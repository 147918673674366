import { IIdValue } from "../../interfaces/IIdValue.interface";

export const euNations: IIdValue[] = [
  { id: "AT", value: "Austria" },
  { id: "BE", value: "Belgio" },
  { id: "BG", value: "Bulgaria" },
  { id: "CY", value: "Cipro" },
  { id: "HR", value: "Croazia" },
  { id: "DK", value: "Danimarca" },
  { id: "EE", value: "Estonia" },
  { id: "FI", value: "Finlandia" },
  { id: "FR", value: "Francia" },
  { id: "DE", value: "Germania" },
  { id: "GR", value: "Grecia" },
  { id: "IE", value: "Irlanda" },
  { id: "LV", value: "Lettonia" },
  { id: "LT", value: "Lituania" },
  { id: "LU", value: "Lussemburgo" },
  { id: "MT", value: "Malta" },
  { id: "NL", value: "Paesi Bassi" },
  { id: "PL", value: "Polonia" },
  { id: "PT", value: "Portogallo" },
  { id: "CZ", value: "Repubblica ceca" },
  { id: "RO", value: "Romania" },
  { id: "SK", value: "Slovacchia" },
  { id: "SI", value: "Slovenia" },
  { id: "ES", value: "Spagna" },
  { id: "SE", value: "Svezia" },
  { id: "HU", value: "Ungheria" },
  { id: "IT", value: "Italia" },
];
