import { useAtom, useSetAtom } from "jotai";
import { IProduct } from "../services/ApiClientBonusXServiceRepository/interfaces/IProduct.interface";
import { clampQtyLimitForService, removeDiscountFromCart, subTotal } from "../services/shoppingCart";
import { CartDiscount, resetCartDiscount } from "../stores/cartDiscountStore";
import { CartItem, CartItems } from "../stores/cartStore";
import { ShoppingCartAndDiscount } from "../stores/shoppingCartStoreAndDiscount";

export const useShoppingCart = () => {
  const [{ cart, discount }, setShoppingCart] = useAtom(ShoppingCartAndDiscount);
  const setDiscount = useSetAtom(CartDiscount);

  const resetDiscount = () => {
    setShoppingCart({ cart: removeDiscountFromCart(cart), discount: resetCartDiscount });
  };

  const addToCart = async (service: IProduct, count: number = 1) => {
    const { discountCode, discountPrice } = discount;
    const newCart = [...cart, { service, count, discountCode, discountPrice }];

    const currentCart = clampQtyLimitForService(newCart);
    await setShoppingCart({ cart: currentCart, discount });
  };

  const emptyCart = () => {
    setShoppingCart({ cart: [], discount: resetCartDiscount });
  };

  const changeQtyItemCart = (serviceId: number, qty: number) => {
    let tmpCart: CartItems = [...cart];
    tmpCart = tmpCart.map((item) => {
      return item.service.id === serviceId ? { ...item, count: qty } : item;
    });

    const currentCart = clampQtyLimitForService(tmpCart);
    setShoppingCart({ cart: currentCart, discount });
  };

  const removeFromCart = (serviceId: number) => {
    const newCart = cart.filter((item: CartItem) => item.service.id !== serviceId);
    if (newCart.length <= 0) return emptyCart();
    setShoppingCart({ cart: newCart, discount });
  };

  const applyDiscountCode = (code: string) => {
    setShoppingCart({ cart, discount: { ...discount, discountCode: code } });
  };

  const computeDiscount = () => {
    setShoppingCart({ cart, discount });
  };

  const serviceIsInCart = (serviceId: number): boolean => {
    return !!cart.filter((item) => item.service.id === serviceId).length;
  };

  return {
    serviceIsInCart,
    computeDiscount,
    applyDiscountCode,
    discount,
    setDiscount,
    resetDiscount,
    cart,
    subtotal: () => subTotal(cart),
    total: () => subTotal(cart) - discount.discountPrice,
    removeFromCart,
    changeQtyItemCart,
    addToCart,
    emptyCart,
  };
};
