if (!Promise.prototype.finally) {
  Promise.prototype.finally = function (callback) {
    if (typeof callback !== "function") {
      return this.then(callback, callback);
    }
    const P: PromiseConstructor = Promise;
    return this.then(
      (value) => P.resolve(callback()).then(() => value),
      (err) =>
        P.resolve(callback()).then(() => {
          throw err;
        })
    );
  };
}

export default Promise;
