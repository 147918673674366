import has from "lodash/has";
import isString from "lodash/isString";
import isObject from "lodash/isObject";
import { GTM } from "./GTMInterfaces";

export function isValidEvent(gtmEvent: unknown): gtmEvent is GTM.Event {
  if (!isObject(gtmEvent)) {
    return false;
  }

  if (!has<unknown>(gtmEvent, "event")) {
    return false;
  }

  const { event } = gtmEvent as object as GTM.Event;

  return isString(event);
}
