import sortBy from "lodash/sortBy";
import isNil from "lodash/isNil";

export const serializeForDiffCheck = (obj: Object) => {
  /*
      trasforma l'oggeto in un array di oggetti, ordinati ed
      elimina quelli con chiave/valore che siano null o undefined
  
      gli oggetti creati in questo modo, vengono trasfomati in stringhe
  
      a questo punto è possibile fare i confronti con un altro oggetto
      ed evidenziare al volo delle differenze, questo rende abbastanza
      efficace "distinctUntilChanged" anche su oggetti mediamente complessi
    */
  const entries = sortBy(
    Object.entries(obj)
      .filter(([key, value]) => !isNil(key) || !isNil(value))
      .map(([key, value]) => ({
        key,
        value,
      }))
  );

  return JSON.stringify(entries);
};
