import axios from "axios";
import { AuthenticationApi } from "bonusx-api-auth-manager";
import { CitizenApi } from "bonusx-api-main-manager";
import { useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";
import { authApiConfig_v1, defaultApiConfig } from "../common/env";
import { AbilityRulesStore } from "../stores/abilityRulesStore";
import { accessTokenSubject$, getUserToken } from "../stores/accessToken";
import { RootStore } from "../stores/app";
import { doLogout, resetStorage } from "../utils/logout";
import { useAccount } from "./useAccount";
import { useNextURL } from "./useNextURL";
import { useRegister } from "./useRegister";

export function useLogin() {
  const { set: setNextURL } = useNextURL();
  const setRootStore = useSetAtom(RootStore);
  const register = useRegister();
  const setAbility = useSetAtom(AbilityRulesStore);
  const { revalidate: revalidateAccount, accountData } = useAccount();

  const getAbilityRules = async () => {
    const authenticationApi = new AuthenticationApi(authApiConfig_v1, undefined, axios);
    const { data } = await authenticationApi.getAbilityRules();
    return setAbility(data);
  };

  const reset = useCallback(() => {
    setRootStore({ type: "RESET" });
    setAbility([]);
    resetStorage();
  }, []);

  const login = useCallback(async function loginWithPassword({ email, password }: { email: string; password: string }) {
    reset();

    const authenticationApi = new AuthenticationApi(authApiConfig_v1, undefined, axios);
    const { data } = await authenticationApi.loginWithPassword({
      email,
      password,
    });

    const userPayload = getUserToken();
    if (!userPayload) {
      throw new Error("Login failed, auth payload is empty");
    }

    accessTokenSubject$.next(userPayload);

    const citizenApi = new CitizenApi(defaultApiConfig, undefined, axios);

    const {
      data: { questionnaire },
    } = await citizenApi.getQuestionnaireFields();

    setRootStore({ type: "UPDATE_QUESTIONNAIRE", payload: { questionnaire, notify: true } });
    setAbility(data?.abilityRules || []);

    await revalidateAccount();

    if (data.nextURL) {
      setNextURL(data.nextURL);
    }

    return data.redisKey;
  }, []);

  const logout = doLogout;

  const result = {
    login,
    logout,
    register,
    reset,
    getAbilityRules,
  };

  return useMemo(() => result, [accountData?.id]);
}
