import { Expose } from "class-transformer";
import { IsOptional, IsString } from "class-validator";
import { IFeatureFlagContextAccountData } from "./feature-flag-context-account-data.interface";

export class FeatureFlagContextAccountData implements IFeatureFlagContextAccountData {
  /**
   * @description this key is required https://configcat.com/docs/sdk-reference/openfeature/js/#evaluation-context
   */
  @Expose()
  @IsString()
  targetingKey!: string;

  @Expose()
  @IsString()
  @IsOptional()
  country?: string;

  @Expose()
  @IsString()
  accountId!: string;

  @Expose()
  @IsString()
  email!: string;

  @Expose()
  @IsString()
  name!: string;

  @Expose()
  @IsString()
  surname!: string;
}
