import { isDevMode } from "../utils/isDev";
import { GTM } from "./gtmEvent/GTMInterfaces";
import { GTMOnceForSession } from "./gtmEvent/GTMOnceForSession";
import { existEventOnceInSession } from "./gtmEvent/existEventOnceInSession";
import { gtmEventEcommercePartitioner } from "./gtmEvent/gtmEventEcommercePartitioner";
import { isEventOnceForSession } from "./gtmEvent/isOnceForSession";
import { isValidEvent } from "./gtmEvent/isValidEvent";
import { isValidEventEcommerce } from "./gtmEvent/isValidEventEcommerce";
import { outputEventAdapter } from "./gtmEvent/outputEventAdapter";

function debugMessage(event: GTM.Event): void {
  if (!isDevMode()) return;
  console.error("Event is invalid:", event);
}

function pushOnDataLayer(event: GTM.EventExtendend): void {
  const outputEvent = outputEventAdapter(event);

  try {
    window.dataLayer.push(outputEvent);
  } catch (error) {
    console.error("failed to push event on dataLayer", error);
  }
}

export function sendGTMEvent(event: GTM.Event | GTM.EventEcommerce): void {
  if (!isValidEvent(event)) {
    return debugMessage(event);
  }

  if (existEventOnceInSession(event)) {
    return;
  }

  if (isEventOnceForSession(event)) {
    GTMOnceForSession.set(event.event, "once");
  }

  if (isValidEventEcommerce(event)) {
    return sendGTMEcommerceEvent(event);
  }

  return pushOnDataLayer(event);
}

export function sendGTMEcommerceEvent(event: GTM.EventEcommerce): void {
  if (!isValidEventEcommerce(event)) {
    return debugMessage(event);
  }

  const eventsCollection = gtmEventEcommercePartitioner(event);

  eventsCollection.forEach((event) => pushOnDataLayer(event));
}
