import * as Sentry from "@sentry/react";
import { default as momentModule } from "moment";
import ReactDOM from "react-dom";
import "reflect-metadata";
import "./assets/css/main.css";
import { APP_VERSION } from "./common/env";
import { sentryConfig } from "./common/sentryConfig";
import featureFlag from "./feature-flag";
import "./index.css";
import "./polyfill/promise";
import { ProvideAppEssentials } from "./provide-app-essentials";
import domReadyPromise from "./utils/domReadyPromise";
// Fixes older browser compatibilities issues (https://github.com/remarkjs/react-markdown/issues/804)
import "core-js/features/object";

window.moment = momentModule;
window.moment.locale("it");
window.year = window.moment().year();

Sentry.init(sentryConfig);
Sentry.setTag("app_version", APP_VERSION);

featureFlag
  .initialize()
  .then(console.log.bind(null, "Feature Flag initialized"))
  .catch(console.error.bind(null, "Feature Flag error:"));

const reactAppTarget = document.getElementById("root");

domReadyPromise()
  .then(function onDomReady() {
    ReactDOM.render(<ProvideAppEssentials />, reactAppTarget);
  })
  .catch(function whenQASmiling(error) {
    console.error("APP  ERROR:", error);
    window.location.href = "/unexpected-error.html";
  });
