import { atomWithStorage } from "jotai/utils";
import R from "../R";

const DEFAULT_MESSAGE_TEXT_COLOR = R.colors.gradientColorPrimaryOne;

export const DISCOUNT_STORAGE_INDEX = "bx-discount";

export type IDiscountStatus = "ok" | "error";

export interface CartDiscountStoreInterface {
  discountMessage: string;
  discountColor: string;
  discountActive: boolean;
  discountCode: string;
  discountPrice: number;
  status: IDiscountStatus;
  loading: boolean;
}

export const resetCartDiscount: CartDiscountStoreInterface = {
  discountMessage: "",
  discountColor: DEFAULT_MESSAGE_TEXT_COLOR,
  discountActive: true,
  discountCode: "",
  discountPrice: 0,
  status: "ok",
  loading: false,
};

export const CartDiscount = atomWithStorage<CartDiscountStoreInterface>(DISCOUNT_STORAGE_INDEX, resetCartDiscount, {
  getItem: (discountStorageKey: string) => {
    const localStoreDiscount = localStorage.getItem(discountStorageKey) || "{}";
    const parsedDiscount: CartDiscountStoreInterface = JSON.parse(localStoreDiscount);

    return parsedDiscount;
  },
  setItem: (discountStorageKey: string, discountData: CartDiscountStoreInterface) => {
    if (discountData.status === "error") return localStorage.removeItem(discountStorageKey);

    return localStorage.setItem(discountStorageKey, JSON.stringify(discountData));
  },
  removeItem: (discountStorageKey: string) => {
    return localStorage.removeItem(discountStorageKey);
  },
});
