import { CitizenApi } from "bonusx-api-main-manager";
import { useAtom } from "jotai";
import { PropsWithChildren, useEffect } from "react";
import { AnyAction } from "redux";
import { defaultApiConfig } from "../common/env";
import { useAbility } from "../hooks/useAbilityRules";
import { useAccount } from "../hooks/useAccount";
import { useIsLogged } from "../hooks/useIsLogged";
import { useShoppingCart } from "../hooks/useShoppingCart";
import { identifyUser } from "../services/identifyUser";
import { RootStore } from "../stores/app";

export function InitializeApp({ children }: Readonly<PropsWithChildren<{}>>): JSX.Element {
  const [rootStore, setRootStore] = useAtom(RootStore);

  const abilityRules = useAbility();
  const isLogged = useIsLogged();
  const { accountData } = useAccount();
  const { computeDiscount } = useShoppingCart();

  useEffect(
    function resetLocalStorageOnLogout() {
      if (isLogged) {
        return;
      }

      return function onUnload(): void {
        setRootStore({ type: "RESET" });
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLogged]
  );

  useEffect(
    function setInitialUserData() {
      // IDENTIFICA L'UTENTE SULLE PIATTAFORME DI TRACCIAMENTO
      if (accountData) {
        identifyUser(accountData);
      }

      // IMPOSTA IL NOME UTENTE PER USO LOCALE
      const currentName = rootStore.updates?.formValues?.Nome;
      if (accountData && accountData.name && accountData.name !== currentName) {
        setRootStore({ type: "SET_FORM_NAME", payload: `${accountData.name?.trim()}` });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [accountData]
  );

  useEffect(
    function lastStepForInitializeApp() {
      if (!isLogged || abilityRules.cannot("readOne", "Citizen")) {
        return;
      }

      computeDiscount();

      downloadQuestionnaire(setRootStore);
    },
    [abilityRules.rules]
  );

  return <>{children}</>;
}

async function downloadQuestionnaire(setRootStore: (action: AnyAction) => AnyAction) {
  try {
    const citizenApi = new CitizenApi(defaultApiConfig);

    const {
      data: { questionnaire },
    } = await citizenApi.getQuestionnaireFields();

    setRootStore({ type: "UPDATE_QUESTIONNAIRE", payload: { questionnaire, notify: true } });
  } catch (error) {
    console.error("FAILED TO LOAD CITIZIEN QUESTIONNAIRE", error);
  }
}
