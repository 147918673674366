import has from "lodash/has";
import isArray from "lodash/isArray";
import size from "lodash/size";
import { isValidEvent } from "./isValidEvent";
import { GTM } from "./GTMInterfaces";

export function isValidEventEcommerce(event: GTM.Event): event is GTM.EventEcommerce {
  if (!isValidEvent(event)) {
    return false;
  }

  if (!has<GTM.Event>(event, "ecommerce.items")) {
    return false;
  }

  const gtmEvent = event as GTM.EventEcommerce;

  if (!isArray(gtmEvent.ecommerce.items)) {
    return false;
  }

  if (size(gtmEvent.ecommerce.items) <= 0) {
    return false;
  }

  return true;
}
