const MAIN_STEPS_INDEX = "main_steps";

export class MainSteps {
  static get = (): { currentForm: string; activeStepName: string } => {
    return JSON.parse(localStorage.getItem(MAIN_STEPS_INDEX) || "{}");
  };

  static set = (state: any) => {
    localStorage.setItem(
      MAIN_STEPS_INDEX,
      JSON.stringify({ currentForm: state.currentForm, activeStepName: state.activeStepName })
    );
  };
}
