import axios from "axios";
import { AuthenticationApi } from "bonusx-api-auth-manager";
import { useAtom, useSetAtom } from "jotai";
import { authApiConfig_v1 } from "../common/env";
import { AbilityRulesStore } from "../stores/abilityRulesStore";
import { accessTokenSubject$, getUserToken } from "../stores/accessToken";
import { RootStore } from "../stores/app";
import { resetStorage } from "../utils/logout";
import { useNextURL } from "./useNextURL";

interface RegisterParamsInterface {
  name?: string;
  surname?: string;
  email: string;
  password: string;
}

export function useRegister() {
  const { nextURL } = useNextURL();

  const [rootStore, setRootStore] = useAtom(RootStore);
  const setAbility = useSetAtom(AbilityRulesStore);

  const reset = () => {
    setRootStore({ type: "RESET" });
    setAbility([]);
    resetStorage();
  };

  return async function register({ email, password, name = "", surname = "" }: RegisterParamsInterface) {
    reset();

    const rootStoreCurrent = rootStore.updates;

    const questionnaire: any = {
      formValues: { ...rootStoreCurrent.formValues },
      stepHistory: rootStoreCurrent.stepHistory,
      currentForm: rootStoreCurrent.currentForm,
      activeStepName: rootStoreCurrent.activeStepName,
    };

    const authenticationApi = new AuthenticationApi(authApiConfig_v1, undefined, axios);

    const { data } = await authenticationApi.register({
      email,
      password,
      name,
      surname,
      questionnaire,
      nextURL,
    });

    accessTokenSubject$.next(getUserToken());

    setAbility(data?.abilityRules || []);

    return data;
  };
}
