import axios, { AxiosResponse } from "axios";
import { DiscountApi, ResponseGetDiscountInformation } from "bonusx-api-main-manager";
import clamp from "lodash/clamp";
import R from "../R";
import { defaultApiConfig } from "../common/env";
import { DISCOUNT_NOT_VALID, SERVICE_WITHOUT_DISCOUNT_CODE, mapErrorMessage } from "../common/errorMessage";
import { ComputeDiscountErrorInterface } from "../interfaces/ComputeDiscountErrorInterface";
import { CartDiscountStoreInterface, resetCartDiscount } from "../stores/cartDiscountStore";
import { CartItem, CartItems } from "../stores/cartStore";

const DEFAULT_MESSAGE_TEXT_COLOR = R.colors.vividGreen;
const ERROR_MESSAGE_TEXT_COLOR = R.colors.superRed;

export const removeDiscountFromCart = (cart: CartItems) => {
  return cart.map((item) => ({ ...item, discountPrice: 0, discountCode: "" }));
};

export const clampQtyLimitForService = (cart: CartItems) => {
  return [...cart].map((item) => {
    const max = item?.service?.itemsAvailablePerOrder || 1;
    const min = 1;
    const count = clamp(item.count, min, max);

    return { ...item, count };
  });
};

export const subTotal = (cart: CartItems = []) => {
  return Number(cart.reduce((prev, curr) => prev + (curr?.service?.price || 0) * curr.count, 0).toFixed(2));
};

const getTotalDiscountFromCart = (cart: CartItems) => {
  return cart?.reduce((prev, curr) => prev + (curr?.discountPrice || 0) * curr.count, 0);
};

export const checkMinMaxSpend = (subTotal: number, minSpend: number = 0, maxSpend: number = 0) => {
  if (minSpend && subTotal < minSpend) {
    return { message: `Per utilizzare questo codice sconto, la spesa minima è ${minSpend} €.`, status: false };
  } else if (maxSpend && subTotal > maxSpend) {
    return { message: `La spesa massima per utilizzare questo codice sconto è ${maxSpend} €.`, status: false };
  }

  return { message: "", status: true };
};

const applyDiscountToCartItem = ({
  item,
  minSpend,
  maxSpend,
  subtotal,
  discountAmount,
  includedProducts = [],
  excludedProducts = [],
  discountCode,
}: {
  item: CartItem;
  minSpend?: number;
  maxSpend?: number;
  subtotal: number;
  discountAmount: number;
  includedProducts?: number[];
  excludedProducts?: number[];
  discountCode: string;
}) => {
  // Controlla se il codice sconto è applicabile al servizio
  let applicableDiscount = true;

  const serviceId = Number(item?.service.id);

  applicableDiscount = applicableDiscount && !isNaN(serviceId);

  if (applicableDiscount && includedProducts.length > 0) {
    applicableDiscount = [includedProducts.includes(serviceId)].every((bool) => bool);
  }

  if (applicableDiscount && excludedProducts.length > 0) {
    applicableDiscount = ![excludedProducts.includes(serviceId)].every((bool) => bool);
  }

  //  Controlla se il sub-totale rientra nei range del codice sconto

  if (typeof minSpend === "number")
    applicableDiscount = !!(applicableDiscount && typeof minSpend === "number" && subtotal >= minSpend);

  if (typeof maxSpend === "number")
    applicableDiscount = !!(applicableDiscount && typeof maxSpend === "number" && subtotal <= maxSpend);

  if (!applicableDiscount) return item;

  const newItem = { ...item };

  const servicePrice = newItem?.service.price || 0;

  newItem.discountPrice = (servicePrice * discountAmount) / 100;
  newItem.discountCode = discountCode;

  return newItem;
};

const applyDiscountCodeToCart = (
  discount: CartDiscountStoreInterface,
  responseApi: AxiosResponse<ResponseGetDiscountInformation>,
  cart: CartItems,
  subtotal: number
) => {
  let newDiscount = { ...discount };
  let newCart = [...cart];

  let discountAmount = responseApi.data.amount || 0;
  let minSpend = responseApi.data.minSpend;
  let maxSpend = responseApi.data.maxSpend;
  let includedProducts = responseApi?.data?.includedProducts;
  let excludedProducts = responseApi?.data?.excludedProducts;

  let { discountCode } = discount;

  newCart = newCart.map((item) =>
    applyDiscountToCartItem({
      item,
      minSpend,
      maxSpend,
      subtotal,
      discountAmount,
      includedProducts,
      excludedProducts,
      discountCode,
    })
  );

  //  conta a quanti servizi è stato applicato il codice sconto

  let appliedDiscountToItems = cart.filter(
    (item) =>
      item !=
      applyDiscountToCartItem({
        item,
        minSpend: undefined,
        maxSpend: undefined,
        subtotal,
        discountAmount,
        includedProducts,
        excludedProducts,
        discountCode,
      })
  );

  const discountPrice = getTotalDiscountFromCart(newCart);

  newDiscount = {
    ...newDiscount,
    discountMessage: responseApi?.data?.message || discount.discountMessage,
    discountPrice,
  };

  const orderAmountRangeIsDiscountable = checkMinMaxSpend(subtotal, minSpend, maxSpend);
  if (!orderAmountRangeIsDiscountable.status && appliedDiscountToItems.length > 0) {
    throw { data: { message: orderAmountRangeIsDiscountable.message } };
  }

  // Se lo sconto non può essere applicato a nessuno dei servizi in carrello

  if (appliedDiscountToItems.length <= 0 && appliedDiscountToItems.length !== newCart.length) {
    throw { data: { message: SERVICE_WITHOUT_DISCOUNT_CODE } };
  }

  if (typeof responseApi.data.amount !== "number") throw { data: { message: DISCOUNT_NOT_VALID } };

  return { discount: newDiscount, cart: newCart };
};

export const computeDiscount = async (
  discountCode: string = "",
  cart: CartItems,
  discount: CartDiscountStoreInterface
) => {
  const discountApi = new DiscountApi(defaultApiConfig, defaultApiConfig.basePath, axios);

  let newCart: CartItems = [...removeDiscountFromCart(cart)];

  const subtotal = subTotal(cart);

  if (discountCode.length <= 0) return Promise.resolve({ discount: resetCartDiscount, cart: newCart });

  let newDiscount = {
    ...discount,
    discountMessage: "",
    discountColor: DEFAULT_MESSAGE_TEXT_COLOR,
    discountCode,
  };

  try {
    const response = await discountApi.getDiscountInformation(discountCode);
    const { discount, cart } = applyDiscountCodeToCart(newDiscount, response, newCart, subtotal);
    newCart = [...cart];
    newDiscount = { ...discount, discountActive: false, status: "ok" };
  } catch (error: ComputeDiscountErrorInterface | any) {
    console.warn("Discount code not accepted:\n", error.data?.message, error);
    // dont reply this interface use, is not good: ComputeDiscountErrorInterface | any
    let message: string | undefined = error.data?.message;
    if (error.response?.data?.message) message = error.response?.data?.message;

    newDiscount = {
      ...newDiscount,
      discountMessage: mapErrorMessage(message),
      discountColor: ERROR_MESSAGE_TEXT_COLOR,
      discountActive: true,
      discountPrice: 0,
      status: "error",
    };
  }

  return Promise.resolve({ discount: { ...newDiscount }, cart: newCart });
};
