import * as Sentry from "@sentry/react";
import { Account } from "bonusx-api-main-manager";
import { APP_VERSION } from "../common/env";
import { getEnvName } from "../utils/getEnvName";
import { GTM } from "../utils/useGTM";
import SHA from "crypto-js/sha1";

let isInitialized = false;

export const identifyUser = ({ id, name, citizen, email }: Account) => {
  if (isInitialized) return;
  isInitialized = true;

  /* 
    GOOGLE TAG MANAGER: identifica utente
  */
  const userDataInitEvent = {
    event: "init",
    user_id: id,
    logged_in: true,
    user_email: email,
    user_email_hash: SHA(email || "").toString(),
  };

  GTM().push(userDataInitEvent);

  /* 
    SENTRY: identifica utente
  */
  Sentry.configureScope((scope) => {
    let userData: { id: string; name?: string } = { id: `${id}` };
    if (getEnvName() !== "production" && typeof name === "string") userData = { ...userData, name: name };

    scope.setUser(userData);
  });

  /* 
   identify user with Hotjar
  */
  if (typeof window.hj === "function") {
    window.hj("identify", id, {
      isCustomer: !!citizen?.requestsPurchased,
      appVersion: APP_VERSION,
    });
  }

  /*
    identify user with ActiveCampaign
  */
  if (typeof window.vgo === "function") {
    try {
      window.vgo("setEmail", email);
    } catch (error) {
      console.error("failed to set email on active campaign", error);
    }
  }
};
