import CodiceFiscale from "codice-fiscale-js";
import isString from "lodash/isString";
import moment, { Moment } from "moment";
import { IRegistrySex } from "../interfaces/IRegistrySex";

const monthFromTaxCode: Record<string, string> = {
  A: "01",
  B: "02",
  C: "03",
  D: "04",
  E: "05",
  H: "06",
  L: "07",
  M: "08",
  P: "09",
  R: "10",
  S: "11",
  T: "12",
};

export const MINIMUM_ACCEPTED_BIRTH_DATE = new Date("1900-01-01");

export function getBirthDateFromTaxCode(taxCode?: string): Date {
  if (typeof taxCode !== "string" || !CodiceFiscale.check(taxCode)) {
    throw new Error("TaxCode is not valid");
  }

  const taxCodeParsed = CodiceFiscale.computeInverse(taxCode);

  const date = moment(`${taxCodeParsed.day}/${taxCodeParsed.month}/${taxCodeParsed.year}`, "DD/MM/YYYY");

  return date.toDate();
}

export function ageGreaterThan18YearFromTaxCode(taxCode: string): boolean {
  if (typeof taxCode !== "string" || !CodiceFiscale.check(taxCode)) {
    throw new Error("TaxCode is not valid");
  }

  const birthDate = computeBirthDateFromTaxCode(taxCode);
  const diffYear = moment().diff(birthDate, "years", true) || 0;
  return diffYear >= 18;
}

export function isBirthDateValid(birthDate: Date, strictMode: boolean): boolean {
  const isAboveMinimumAcceptedDate = isBirthDateAboveMinimumAcceptedDate(birthDate);
  const isDateBirthLowerThanToday = birthDate < new Date();

  let isAdult = true;
  if (strictMode) {
    isAdult = ageGreaterOrEqualThan18YearFromDate(birthDate);
  }

  return isAboveMinimumAcceptedDate && isDateBirthLowerThanToday && isAdult;
}

export function ageGreaterOrEqualThan18YearFromDate(birthDate: Date): boolean {
  if (!birthDate) {
    return true;
  }

  const diffYear = moment().diff(birthDate, "years", true) || 0;

  return diffYear >= 18;
}

export function isBirthDateAboveMinimumAcceptedDate(birthDate: Date) {
  if (!birthDate) {
    return false;
  }
  return birthDate >= MINIMUM_ACCEPTED_BIRTH_DATE;
}

export function isValidTaxCodeOfAdult(taxCode: string): boolean {
  return CodiceFiscale.check(taxCode) && ageGreaterThan18YearFromTaxCode(taxCode);
}

export function computeBirthDateFromTaxCode(taxCode: string, priorityToAdult: boolean = true): Date {
  if (typeof taxCode !== "string" || !CodiceFiscale.check(taxCode)) {
    throw new Error("TaxCode is not valid");
  }
  let year;
  const taxCodeUpperCase = upperCaseTaxCode(taxCode);
  const actualYear = moment().year();

  const yearSubstring = taxCodeUpperCase.substring(6, 8);
  const yearRaw = Number(yearSubstring) + 2000;
  const minYearForAdult = actualYear - 18;

  const isComputeTaxCodeForAdultAndisYearComputedForMinor = priorityToAdult && yearRaw > minYearForAdult;
  const isYearComputedFromFuture = yearRaw > actualYear;
  if (isComputeTaxCodeForAdultAndisYearComputedForMinor || isYearComputedFromFuture) {
    year = yearRaw - 100;
  } else {
    year = yearRaw;
  }

  const monthChar = taxCodeUpperCase[8];
  const monthString = monthFromTaxCode[monthChar];

  // month had been decreased by one because new Date() increase the month by one.
  const month = Number(monthString) - 1;

  const dayString = taxCodeUpperCase[9] + taxCodeUpperCase[10];
  const dayRaw = Number(dayString);
  const day = dayRaw > 40 ? dayRaw - 40 : dayRaw;

  const fullDate = new Date(year, month, day);

  return fullDate;
}

function upperCaseTaxCode(taxCode: string): string {
  if (typeof taxCode !== "string") {
    throw new Error("TaxCode is not valid");
  }
  return taxCode.toUpperCase();
}

export function areDayAndMonthEqual(dateFromTaxCode: Moment, dateFromQuestionnaire: Moment): boolean {
  const areDaysEqual = dateFromTaxCode.date() === dateFromQuestionnaire.date();
  const areMonthsEqual = dateFromTaxCode.month() + 1 === dateFromQuestionnaire.month() + 1;

  return areDaysEqual && areMonthsEqual;
}

export function areYearsEqual(dateFromTaxCode: Moment, dateFromQuestionnaire: Moment): boolean {
  const dateFromTaxCodeComputedPlus100Years = moment(dateFromTaxCode, "DD/MM/YYYY").add(100, "years");

  const dateFromTaxCodeComputedSubtract100Years = moment(dateFromTaxCode, "DD/MM/YYYY").subtract(100, "years");

  const dateFromQuestionnaireYear = dateFromQuestionnaire.year();
  const dateFromTaxCodeYear = dateFromTaxCode.year();

  const areYearsEqual =
    dateFromTaxCodeYear === dateFromQuestionnaireYear ||
    dateFromTaxCodeComputedPlus100Years.year() === dateFromQuestionnaireYear ||
    dateFromTaxCodeComputedSubtract100Years.year() === dateFromQuestionnaireYear;

  return areYearsEqual;
}

export function areDatesEqual(dateFromTaxCode: string, dateFromQuestionnaire: string): boolean {
  const dateFromTaxCodeComputed = moment(dateFromTaxCode, "DD/MM/YYY");
  const dateFromQuestionnaireComputed = moment(dateFromQuestionnaire, "DD/MM/YYY");

  if (!areDayAndMonthEqual(dateFromTaxCodeComputed, dateFromQuestionnaireComputed)) {
    return false;
  }

  const areSecondPartEqual = areYearsEqual(dateFromTaxCodeComputed, dateFromQuestionnaireComputed);

  return areSecondPartEqual;
}

export function getRegistrySexFromTaxCode(taxCode?: string): IRegistrySex {
  const isValidTaxCode = isString(taxCode) && CodiceFiscale.check(taxCode);
  if (!isValidTaxCode) {
    throw new Error("TaxCode is not valid");
  }

  const { gender } = CodiceFiscale.computeInverse(taxCode);
  return gender;
}

export function getBirthPlaceFromTaxCode(taxCode?: string): string {
  const isValidTaxCode = isString(taxCode) && CodiceFiscale.check(taxCode);
  if (!isValidTaxCode) {
    throw new Error("TaxCode is not valid");
  }

  const { birthplace: birthPlace } = CodiceFiscale.computeInverse(taxCode);
  return birthPlace;
}
