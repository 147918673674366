import { GTM_ONCE_FOR_SESSION_KEY } from "../../common/env";

export class GTMOnceForSession {
  static get() {
    try {
      const session = sessionStorage.getItem(GTM_ONCE_FOR_SESSION_KEY) || "{}";

      return JSON.parse(session);
    } catch (error) {
      console.error(error);
    }
    return {};
  }
  static set(key: string, value: string) {
    let session = GTMOnceForSession.get();
    session = Object.assign(session, { [key]: value });

    const serializedSession = JSON.stringify(session);
    sessionStorage.setItem(GTM_ONCE_FOR_SESSION_KEY, serializedSession);

    return session;
  }
  static exist(key: string): boolean {
    try {
      const session = GTMOnceForSession.get();
      const sessionKeysCollection = Object.keys(session);

      return sessionKeysCollection.includes(key);
    } catch (error) {
      console.error(error);
    }
    return false;
  }
}
