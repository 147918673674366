import { citizenshipCollection } from "../assets/collections/citizenshipCollection";
import { euNations } from "../assets/collections/euNations";
import { validateBirthDateFields } from "../utils/birthDateUtils";
import { normalizeQuestionnaireArrayValue } from "../utils/normalizeQuestionnaireArrayValue";

const euNationsCountryCode = euNations.map((nation) => nation.id);

window.isOneOfAvailableNationSelected = function citizenshipSelectionNextStepEval(
  citizenshipValue?: string | string[]
) {
  const citizenshipValues = normalizeQuestionnaireArrayValue(citizenshipValue ?? ["IT"]);

  const isOneOfAvailableNationSelected = citizenshipValues.some((selectedCitizenship) => {
    if (!selectedCitizenship) return false;

    return euNationsCountryCode.includes(selectedCitizenship);
  });

  return isOneOfAvailableNationSelected;
};

const citizenship = {
  name: "citizenship",
  title: "Dati personali",
  iconUrl: "/images/identity.svg",
  sections: [
    {
      name: "Dati personali",
      forms: [
        {
          stepname: "2",
          subtitle: ["Conferma la tua data di nascita"],
          guided: "Come facciamo a saperla? L'abbiamo ricavata dal codice fiscale. Verifica che sia giusta :)",
          nextstep: "3",
          fields: [
            {
              name: `DataDiNascita`,
              placeholder: "Data (gg/mm/aaaa)",
              type: "date",
              validation: "eval",
              validateBirthDate: validateBirthDateFields,
              errorText:
                "La data di nascità appartiene ad un minorenne, il formato non è valido o è differente dal codice fiscale attuale",
              expression: `field.validateBirthDate(v)`,
              optional: false,
            },
          ],
        },
        {
          stepname: "3",
          subtitle: ["Seleziona la tua cittadinanza"],
          guided:
            "Hai la cittadinanza in più Stati? Puoi selezionarne più di uno dal menù a tendina nella barra in alto.",
          nextstep: [
            "switch:[",
            `{"eval":"window.isOneOfAvailableNationSelected(v.paesi_cittadinanza)", "step":"4"},`,
            `{"eval":true, "step":"3_2"}`,
            "]",
          ],
          fields: [
            {
              name: `paesi_cittadinanza`,
              type: "multipleselection",
              options: [
                ...(citizenshipCollection.map((citizenship) => ({ [citizenship.value]: citizenship.name })) || []),
              ],
              validation: "has",
              default: "IT",
              placeholder: "Seleziona un valore",
              errorText: "Il campo è obbligatorio per procedere",
              optional: false,
            },
          ],
        },
        {
          stepname: "3_2",
          subtitle: ["Scegli tra queste opzioni su di te"],
          nextstep: [
            "switch:[",
            `{"eval":"(v.TipologiaCittadino || []).includes('4')", "step":"RESET"},`,

            `{"eval":true, "step":"4"}`,
            "]",
          ],
          fields: [
            {
              name: "TipologiaCittadino",
              dependencies: `paesi_cittadinanza`,
              type: "selection",
              options: [
                { "": "Seleziona un'opzione" },
                {
                  "1": "Sei familiare di un/a cittadino/a italiano/a o dell'Unione Europea e hai il diritto di soggiorno",
                },
                { "2": "Hai il permesso di soggiorno UE per soggiornanti di lungo periodo" },
                { "3": "Sei titolare di protezione internazionale" },
                { "4": "Nessuna delle precedenti" },
              ],
              validation: "selected",
              errorText: "Il campo è obbligatorio per procedere",
              optional: false,
            },
          ],
        },
        {
          stepname: "4",
          subtitle: ["Seleziona il tuo Paese di residenza"],
          nextstep: [
            "switch:[",
            `{"eval":"(v.paese_residenza || []).includes('IT')", "step":"4_2"},`,

            `{"eval":true, "step":"5"}`,
            "]",
          ],
          fields: [
            {
              name: "paese_residenza",
              type: "nationchoice",
              validation: "has",
              default: "IT",
              errorText: "Il campo è obbligatorio per procedere",
              optional: false,
            },
          ],
        },
        {
          stepname: "4_2",
          subtitle: ["Seleziona il tuo Comune di residenza"],
          placeholder: "Inserisci un comune",
          nextstep: "5",
          fields: [
            {
              name: "applicant_PosizioneResidenza",
              dependencies: "paese_residenza",
              placeholder: "Inserisci o scegli un comune",
              type: "autocomplete-plaintext-comune",
              validation: "has",
              errorText: "Il campo è obbligatorio per procedere",
              optional: false,
            },
          ],
        },
        {
          stepname: "5",
          subtitle: ["Seleziona la tua attività principale"],
          nextstep: "6",
          fields: [
            {
              name: "AttivitaPrincipale",
              type: "selection",
              options: [
                { "": "Seleziona una attività" },
                { "1": "Lavoro dipendente a tempo indeterminato" },
                { "2": "Lavoro dipendente a tempo determinato o con contratto di apprendistato" },
                { "3": "Lavoro con contratto di somministrazione (“interinale”)" },
                {
                  "4": "Lavoro o disoccupazione con sostegno al reddito (cassa integrazione, NASpI, lavori socialmente utili, ecc.) ",
                },
                { "5": "Lavoro parasubordinato (coll. a progetto o Co.Co.Co)" },
                { "6": "Lavoro accessorio (con voucher, occasionale, tirocinio/stage, ecc.)" },
                { "7": "Lavoro autonomo" },
                { "8": "Senza occupazione" },
                { "9": "Pensione" },
                { "10": "Cura della casa (casalinga/o)" },
                { "11": "Studio (studente universitario, liceale, ecc.)" },
                { "12": "Altro" },
              ],
              validation: "selected",
              errorText: "Seleziona un'attività per procedere",
              optional: false,
            },
          ],
        },
        {
          stepname: "6",
          subtitle: ["Hai una disabilità certificata?"],
          yesno: true,
          nextstep: "6_2:end",
          fields: [
            {
              name: "DisabilitaCertificata",
              placeholder: "",
              type: "yesno",
              optional: false,
            },
          ],
        },
        {
          stepname: "6_2",
          subtitle: ["Di che tipo?"],
          guided: [
            "Non autosufficiente: persona che necessita di assistenza in modo continuato (per lavarsi, vestirsi, nutrirsi o muoversi) a causa di malattie croniche, anzianità o problemi psico-fisici.",
          ],
          nextstep: ["end"],
          fields: [
            {
              name: "TipologiaDisabilitaCertificata",
              dependencies: "DisabilitaCertificata",
              type: "selection",
              options: [
                { "": "Seleziona un'opzione" },
                { "1": "Bassa" },
                { "2": "Media" },
                { "3": "Grave" },
                { "4": "Non autosufficiente" },
              ],
              validation: "selected",
              errorText: "Seleziona un'attività per procedere",
              optional: false,
            },
          ],
        },
      ],
    },
  ],
};

export default citizenship;
