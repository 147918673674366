const family = {
  name: "family",
  title: "Nucleo Familiare",
  iconUrl: "/images/people.svg",
  sections: [
    {
      name: "Nucleo familiare",
      forms: [
        {
          stepname: "7a",
          subtitle: ["%Nome%, quante persone oggi fanno parte del tuo nucleo (incluso te)?"],
          guided: [
            "Nucleo familiare: insieme di adulti e bambini che hanno residenza nella stessa abitazione e sono legati da matrimonio o unione civile, parentela, affinità (parenti del coniuge), tutela o adozione.",
            "Inoltre, ne fanno parte:",
            "• i coniugi con diversa residenza, purché non separati o divorziati;",
            "• i figli maggiorenni con diversa residenza se con età inferiore ai 26 anni, non sposati, senza figli e a carico IRPEF. Il figlio è considerato a carico IRPEF se ha avuto nel [year-2] un reddito annuo inferiore a 2.840,51 € o, se minore di 24 anni, un reddito annuo inferiore a 4.000 €.",
          ],
          nextstep: "check:<:Membrinucleo:2:7i:7b",
          fields: [
            {
              name: "Membrinucleo",
              placeholder: "Membri nucleo familiare",
              type: "number",
              validation: "eval",
              expression: "!isNaN(v['Membrinucleo']) && v['Membrinucleo']>=1 && v['Membrinucleo']<=25",
              errorText: "Il numero deve essere compreso tra 1 e 25",
              optional: false,
            },
          ],
        },
        {
          stepname: "7b",
          subtitle: ["Quanti figli sono inclusi nel tuo nucleo? Va bene anche 0"],
          nextstep: "eval:!isNaN(v['Figli']) && v['Figli'] > 0:7c:7i",
          fields: [
            {
              name: "Figli",
              dependencies: ["Membrinucleo"],
              type: "number",
              placeholder: "Numero figli",
              validation: "eval",
              expression: "!isNaN(v['Figli']) && v['Figli'] <= v['Membrinucleo'] - 1",
              errorText:
                "eval:isNaN(v['Figli']):Inserisci un numero corretto:Il numero di figli non può essere uguale o maggiore di quello dei componenti del nucleo familiare",
              optional: false,
            },
          ],
        },
        {
          stepname: "7c",
          subtitle: ["Quanti sono minorenni?"],
          nextstep: "eval:!isNaN(v['Figliminori']) && v['Figliminori']<=v['Figli'] && v['Figliminori']>0:7e_2:7i",
          fields: [
            {
              name: "Figliminori",
              placeholder: "Figli minorenni",
              dependencies: ["Membrinucleo", "Figli"],
              type: "number",
              validation: "eval",
              expression: "!isNaN(v['Figliminori']) && v['Figliminori'] <= v['Figli']",
              errorText:
                "eval:isNaN(v['Figliminori']):Inserisci un numero corretto:Il numero di figli minori non può essere maggiore di quello dei figli",
              optional: false,
            },
          ],
        },
        {
          stepname: "7e_2",
          subtitle: ["Tra questi c'è un minore adottato da meno di 12 mesi o in affido pre-adottivo?"],
          yesno: true,
          nextstep: [`eval:((v['Membrinucleo']-1) - v['Figliminori']) == 0:7g:7h`],
          fields: [
            {
              name: "Figliadottati",
              dependencies: ["Membrinucleo", "Figli", "Figliminori"],
              type: "yesno",
              validation: "eval",
              expression: "!isNaN(v['Figliadottati']) && v['Figliadottati']<=v['Figli']",
              optional: false,
            },
          ],
        },
        {
          stepname: "7g",
          subtitle: ["Sei un genitore solo?"],
          guided: [
            "Seleziona SI se il tuo nucleo familiare è composto esclusivamente da te e dai tuoi figli minorenni.",
            "Seleziona NO in tutti gli altri casi, compresi quelli in cui l'altro genitore:",
            "- non fa parte del nucleo, ma ha riconosciuto i figli;",
            "- non è sposato/a o unito/a civilmente con un'altra persona;",
            "- non ha figli con un'altra persona;",
            "- non versa assegni periodici per il mantenimento dei figli;",
            "- non è stato/a allontanato/a dal nucleo familiare con provvedimento del giudice;",
            "- non risulta estraneo al tuo nucleo familiare in base ad accertamento dall'autorità pubblica.",
          ],
          yesno: true,
          nextstep: "7i:7i",
          fields: [
            {
              dependencies: ["Membrinucleo", "Figli", "Figliminori"],
              name: "Genitoresolo",
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "7h",
          subtitle: [
            "Entrambi i genitori (o l'unico genitore) dei minori hanno lavorato per almeno sei mesi nel [year-2]?",
          ],
          guided: [
            "In alcuni casi si tiene conto della situazione lavorativa di due anni prima rispetto all'anno attuale (il [year-2] per il [year]) per il calcolo del diritto a bonus o servizi pubblici agevolati.",
            "Seleziona SI se il tuo nucleo familiare è composto da minori:",
            "• con un solo genitore che ha lavorato per almeno 6 mesi nel [year-2];",
            "• con entrambi i genitori che hanno lavorato per almeno 6 mesi nel [year-2] (vanno, quindi, esclusi i casi in cui uno solo dei due genitori o nessuno abbia lavorato per almeno 6 mesi).",
            "",
            "Seleziona NO in tutti gli altri casi.",
          ],
          yesno: true,
          nextstep: "7i:7i",
          fields: [
            {
              dependencies: ["Membrinucleo", "Figli", "Figliminori", "Genitoresolo"],
              name: "Genitorilavoranti",
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "7i",
          subtitle: [
            "Quanti componenti del nucleo hanno un certificato di disabilità media o grave o non autosufficienza? Va bene anche 0",
          ],
          guided: [
            "Non autosufficiente: persona che necessita di assistenza in modo continuato (per esempio, per lavarsi, vestirsi, nutrirsi, o muoversi) a causa di malattie croniche, dell’anzianità o di problematiche psico-fisiche.",
          ],
          nextstep: "eval:v['Membrinucleo'] == 1:end:7j",
          fields: [
            {
              dependencies: ["Membrinucleo"],
              name: "Membridisabili",
              type: "number",
              placeholder: "Familiari con disabilità",
              validation: "eval",
              expression:
                "!isNaN(v['Membridisabili']) && v['Membridisabili']>=0 && v['Membridisabili']<=v['Membrinucleo']",
              errorText:
                "eval:isNaN(v['Membridisabili']):Inserisci un numero corretto:Il numero di componenti disabili o non autosufficienti non può essere maggiore di quello dei componenti del nucleo familiare",
              optional: false,
            },
          ],
        },
        {
          stepname: "7j",
          subtitle: [
            "Ci sono componenti del nucleo familiare ricoverati in ambiente residenziale socio-sanitario o in convivenza anagrafica?",
          ],
          guided: [
            "Ambiente residenziale socio-sanitario: persona ricoverata presso residenze socio sanitarie assistenziali come RSA, RSSA o residenze protette.",
            "Convivenza anagrafica: persone che vivono abitualmente in un istituto religioso, di pena o militare o in una casa di cura o di riposo.",
          ],
          nextstep: "7j_2:end",
          yesno: true,
          fields: [
            {
              dependencies: ["Membrinucleo"],
              name: "Convivenza",
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "7j_2",
          subtitle: ["Quanti?"],
          nextstep: "end",
          fields: [
            {
              dependencies: ["Membrinucleo", "Convivenza"],
              name: "Membriconvivenza",
              type: "number",
              validation: "eval",
              expression:
                "!isNaN(v['Membriconvivenza']) && (v['Membriconvivenza']>0 && v['Membriconvivenza']<=v['Membrinucleo'])",
              errorText:
                "eval:isNaN(v['Membriconvivenza']):Inserisci un numero corretto:Il numero di componenti in convivenza anagrafica deve essere maggiore di zero e non può essere maggiore di quello dei componenti del nucleo familiare",
              optional: false,
            },
          ],
        },
      ],
    },
  ],
};
export default family;
