import axios from "axios";
import { AuthenticationApi } from "bonusx-api-auth-manager";
import cookie from "js-cookie";
import { cache } from "swr";
import { BONUSX_ACCESS_TOKEN, authApiConfig_v1 } from "../common/env";
import { localStorageSelectiveClean } from "./localStorageSelectiveClean";

const authenticationApi = new AuthenticationApi(authApiConfig_v1, undefined, axios);

function resetStorage() {
  localStorageSelectiveClean();
  cookie.remove(BONUSX_ACCESS_TOKEN);
  cache.clear();
}

function doLogout() {
  return authenticationApi.logout().finally(() => {
    resetStorage();
  });
}

export { doLogout, resetStorage };
