import LocalizedStrings from "react-localization";

export const localization = new LocalizedStrings({
  it: {
    appConfirm: "Conferma",
    appCancel: "Annulla",
    appClose: "Chiudi",
    appSend: "Invia",
    appSave: "Salva",
    appYes: "Sì",
    appNo: "No",
    appOptional: "facoltativo",

    // Layout sections
    sectionDashboard: "Dashboard",
    sectionStartupBuilder: "Startup Builder",
    sectionTraining: "Formazione",
    sectionSuggestedSuppliers: "Fornitori consigliati",
    sectionDiscounts: "Sconti",
    sectionStartups: "Startup",
    sectionCoaches: "Coach",
    sectionPremiumMembers: "Membri premium",
    sectionCalendar: "Calendario",
    sectionBadges: "Badge",
    sectionFaq: "FAQ",
    sectionBetterReplies: "Risposte migliori",

    // Appbar
    appbarDashboard: "Dashboard",
    appbarTraining: "Formazione",
    appbarDiscounts: "Sconti",
    appbarSuppliers: "Fornitori",

    // Drawer buttons
    switchToPremium: "Passa a Premium",
    discoverStartupBuilder: "Scopri Startup Builder",

    // Drawer profile level
    profileEfficacy: "Efficacia profilo: ",
    profileEfficacyMin: "Base",
    profileEfficacyMid: "Intermedia",
    profileEfficacyMax: "Massima",

    // HOME

    // Home content
    hello: "Ciao",
    happyToSeeYouAgain: ", felici di rivederti!",
    homeEncouragement: "Come possiamo aiutarti oggi a diventare un imprenditore migliore?",

    startupBuilder: "Startup Builder",
    trainingArea: "Area formativa",
    softwareDiscounts: "Risparmia sul software",
    findTheRightPartner: "Trova il partner giusto",

    discoverMore: "Scopri di più",
    discover: "Scopri",
    goToTrainingArea: "Vai all'area formativa",
    goToDiscounts: "Vai agli sconti",
    goToSuppliers: "Vai ai fornitori ora",
    goToStartupBuilder: "Accedi a Startup Builder",

    inProgress: "In corso...",

    // Home banners

    startupGeeksPremium: "Bonus X Premium",
    sgPremiumBannerDescription: '"Confrontati con +400 founders e accedi a formazione e sconti aggiuntivi"',

    // Home side

    communityStartup: "Startup della community",
    communityStartupDescription:
      "Tutte le startup in vari settori presenti nella community di Bonus X pronte per essere conosciute.",
    communityCoaches: "Coach della Community",
    communityCoachesDescription:
      "I migliori esperti in vari settori, a disposizione per rispondere alle domande nella community.",

    calendar: "Calendario",
    badges: "Badge",
    communityReplies: "Le risposte dalla community",
    frequentQuestions: "Le domande frequenti",

    unlockPremiumFeatures: "Sblocca le funzionalità Premium",

    communityMembers: "Membri della community",
    communityMembersDescription: "Conosci nuovi membri della community di Bonus X e impara dalle loro esperienze.",
    membersYouCouldKnow: "Membri che potresti conoscere",

    // Startup Builder
    builderSignupBack: "Indietro",
    builderSignupNext: "Avanti",
    builderSignupComplete: "Invia candidatura",
    builderSignupSaveAndContinue: "Salva e continua",
    builderSignupLoginTitle: "Email già registrata",
    builderSignupLoginMessage:
      "Questa email risulta già registrata, accedi per riprendere la candidatura allo Startup Builder!",
    builderSignupLoginButton: "Accedi",
    builderSignupPlaceholderText: "Scrivi qui la tua risposta",
    builderSignupPlaceholderSelect: "Scegli la risposta",
  },
});
