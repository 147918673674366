import { ConfigInterface } from "swr";

const SWR_BASE_CONFIG_DEDUPING_INTERVAL = 2000;
const SWR_BASE_CONFIG_REQUEST_TIMEOUT = 5000;

export const swrBaseConfig: ConfigInterface = {
  dedupingInterval: SWR_BASE_CONFIG_DEDUPING_INTERVAL,
  refreshWhenOffline: false,
  loadingTimeout: SWR_BASE_CONFIG_REQUEST_TIMEOUT,
  shouldRetryOnError: false,
  revalidateOnReconnect: false,
  revalidateOnFocus: false,
};
