import { atomWithObservable } from "jotai/utils";
import cookie from "js-cookie";
import { interval, merge, of, Subject } from "rxjs";
import { distinctUntilChanged, startWith, switchMap } from "rxjs/operators";
import { getCookie } from "../utils/getCookie";
import { BONUSX_ACCESS_TOKEN } from "./../common/env";

import jwt_decode from "jwt-decode";
import { JWTUserPayload } from "../interfaces/JWTUserPayload";

export const jwtPayloadAdapter = (jwtString: string) => {
  return jwt_decode<JWTUserPayload>(jwtString);
};

export const getUserToken = () => {
  const cookieToken = getCookie(BONUSX_ACCESS_TOKEN);
  if (!cookieToken) return;
  try {
    return jwtPayloadAdapter(cookieToken);
  } catch (error) {
    cookie.remove(BONUSX_ACCESS_TOKEN);
    console.error("Failed to decode JWT Token");
  }
};

export const accessTokenSubject$ = new Subject<JWTUserPayload | undefined>();

const accessToken$ = interval(1000).pipe(
  startWith(getUserToken()),
  switchMap(() => of(getUserToken()))
);

export const accessTokenStore = atomWithObservable(
  () =>
    merge(accessToken$, accessTokenSubject$.pipe(switchMap(() => of(getUserToken())))).pipe(
      distinctUntilChanged((previous, current) => {
        return previous?.session === current?.session;
      })
    ),
  { initialValue: getUserToken() }
);
