interface SessionVariable {
  information_questionnaire_started: boolean;
  chat_icon_visible: boolean;
}

export function get(key: keyof SessionVariable) {
  return sessionStorage.getItem(key);
}

export function set<K extends keyof SessionVariable>(key: K, value: SessionVariable[K]) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function remove(key: keyof SessionVariable) {
  sessionStorage.removeItem(key);
}
