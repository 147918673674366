import chunk from "lodash/chunk";
import { GTM } from "./GTMInterfaces";

function replaceItemsOfEventEcommerce(event: GTM.EventEcommerce, itemsChunk: GTM.Item[]): GTM.EventEcommerce {
  const originalEvent: GTM.EventEcommerce = { ...event };
  const eventWithItemsReplacedWithItemChunk = {
    ...originalEvent,
    ecommerce: { ...originalEvent.ecommerce, items: itemsChunk },
  };

  return eventWithItemsReplacedWithItemChunk;
}

export function gtmEventEcommercePartitioner(event: GTM.EventEcommerce): GTM.EventEcommerce[] {
  const eventItems: GTM.Item[] = event.ecommerce.items;
  const maxOfItemsForEvent = 12;
  const eventItemsPartitioned = chunk<GTM.Item>(eventItems, maxOfItemsForEvent);

  const eventsCollection = eventItemsPartitioned.map(function (items) {
    return replaceItemsOfEventEcommerce(event, items);
  });

  return eventsCollection;
}
