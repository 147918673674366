import isUndefined from "lodash/isUndefined";
import moment from "moment";
import { ageGreaterOrEqualThan18YearFromDate, areDatesEqual } from "./tax-code-utils";

export interface FormFields {
  [fieldName: string]: unknown;
}

export function validateBirthDateFields(fields: FormFields): boolean {
  const dateBirth = fields["DataDiNascita"] as string;
  const dateBirthToObject = moment(dateBirth, "DD/MM/YYYY").toDate();

  if (!ageGreaterOrEqualThan18YearFromDate(dateBirthToObject)) {
    return false;
  }

  if (isUndefined(fields["PrevDataDiNascita"])) {
    return true;
  }
  const prevDateBirth = fields["PrevDataDiNascita"] as string;

  return areDatesEqual(prevDateBirth, dateBirth);
}
