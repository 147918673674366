export const baseNationCollection = [
  { name: "Afghanistan", value: "AF" },
  { name: "Albania", value: "AL" },
  { name: "Algeria", value: "DZ" },
  { name: "Andorra", value: "AD" },
  { name: "Angola", value: "AO" },
  { name: "Anguilla", value: "AI" },
  { name: "Antigua e Barbuda", value: "AG" },
  { name: "Arabia Saudita", value: "SA" },
  { name: "Argentina", value: "AR" },
  { name: "Armenia", value: "AM" },
  { name: "Aruba", value: "AW" },
  { name: "Australia", value: "AU" },
  { name: "Austria", value: "AT" },
  { name: "Azerbaigian", value: "AZ" },
  { name: "Bahamas", value: "BS" },
  { name: "Bahrein", value: "BH" },
  { name: "Bangladesh", value: "BD" },
  { name: "Barbados", value: "BB" },
  { name: "Belgio", value: "BE" },
  { name: "Belize", value: "BZ" },
  { name: "Benin", value: "BJ" },
  { name: "Bermuda", value: "BM" },
  { name: "Bhutan", value: "BT" },
  { name: "Bielorussia", value: "BY" },
  { name: "Bolivia", value: "BO" },
  { name: "Bosnia-Erzegovina", value: "BA" },
  { name: "Botswana", value: "BW" },
  { name: "Brasile", value: "BR" },
  { name: "Brunei Darussalam", value: "BN" },
  { name: "Bulgaria", value: "BG" },
  { name: "Burkina Faso", value: "BF" },
  { name: "Burundi", value: "BI" },
  { name: "Cambogia", value: "KH" },
  { name: "Camerun", value: "CM" },
  { name: "Canada", value: "CA" },
  { name: "Capo Verde", value: "CV" },
  { name: "Ciad", value: "TD" },
  { name: "Cile", value: "CL" },
  { name: "Cina", value: "CN" },
  { name: "Cipro", value: "CY" },
  { name: "Città del Vaticano", value: "VA" },
  { name: "Colombia", value: "CO" },
  { name: "Comore", value: "KM" },
  { name: "Congo", value: "CG" },
  { name: "Corea del Nord", value: "KP" },
  { name: "Corea del Sud", value: "KR" },
  { name: "Costa d'Avorio", value: "CI" },
  { name: "Costa Rica", value: "CR" },
  { name: "Croazia", value: "HR" },
  { name: "Cuba", value: "CU" },
  { name: "Curaçao", value: "CW" },
  { name: "Danimarca", value: "DK" },
  { name: "Dominica", value: "DM" },
  { name: "Ecuador", value: "EC" },
  { name: "Egitto", value: "EG" },
  { name: "El Salvador", value: "SV" },
  { name: "Emirati Arabi Uniti", value: "AE" },
  { name: "Eritrea", value: "ER" },
  { name: "Estonia", value: "EE" },
  { name: "Eswatini", value: "SZ" },
  { name: "Etiopia", value: "ET" },
  { name: "Figi", value: "FJ" },
  { name: "Filippine", value: "PH" },
  { name: "Finlandia", value: "FI" },
  { name: "Francia", value: "FR" },
  { name: "Gabon", value: "GA" },
  { name: "Gambia", value: "GM" },
  { name: "Georgia", value: "GE" },
  { name: "Germania", value: "DE" },
  { name: "Ghana", value: "GH" },
  { name: "Giamaica", value: "JM" },
  { name: "Giappone", value: "JP" },
  { name: "Gibilterra", value: "GI" },
  { name: "Gibuti", value: "DJ" },
  { name: "Giordania", value: "JO" },
  { name: "Grecia", value: "GR" },
  { name: "Grenada", value: "GD" },
  { name: "Groenlandia", value: "GL" },
  { name: "Guam", value: "GU" },
  { name: "Guatemala", value: "GT" },
  { name: "Guernsey", value: "GG" },
  { name: "Guinea", value: "GN" },
  { name: "Guinea equatoriale", value: "GQ" },
  { name: "Guinea-Bissau", value: "GW" },
  { name: "Guyana", value: "GY" },
  { name: "Haiti", value: "HT" },
  { name: "Honduras", value: "HN" },
  { name: "India", value: "IN" },
  { name: "Indonesia", value: "ID" },
  { name: "Iran", value: "IR" },
  { name: "Iraq", value: "IQ" },
  { name: "Irlanda", value: "IE" },
  { name: "Islanda", value: "IS" },
  { name: "Isola di Man", value: "IM" },
  { name: "Isole Cayman", value: "KY" },
  { name: "Isole Cook (NZ)", value: "CK" },
  { name: "Isole Fær Øer", value: "FO" },
  { name: "Isole Falkland (Malvine)", value: "FK" },
  { name: "Isole Marshall", value: "MH" },
  { name: "Isole Pitcairn", value: "PN" },
  { name: "Isole Salomone", value: "SB" },
  { name: "Isole Turks e Caicos", value: "TC" },
  { name: "Isole Vergini Britanniche", value: "VG" },
  { name: "Israele", value: "IL" },
  { name: "Italia", value: "IT" },
  { name: "Jersey", value: "JE" },
  { name: "Kazakhstan", value: "KZ" },
  { name: "Kenya", value: "KE" },
  { name: "Kirghizistan", value: "KG" },
  { name: "Kiribati", value: "KI" },
  { name: "Kosovo", value: "XK" },
  { name: "Kuwait", value: "KW" },
  { name: "Laos", value: "LA" },
  { name: "Lesotho", value: "LS" },
  { name: "Lettonia", value: "LV" },
  { name: "Libano", value: "LB" },
  { name: "Liberia", value: "LR" },
  { name: "Libia", value: "LY" },
  { name: "Liechtenstein", value: "LI" },
  { name: "Lituania", value: "LT" },
  { name: "Lussemburgo", value: "LU" },
  { name: "Macedonia del Nord", value: "MK" },
  { name: "Madagascar", value: "MG" },
  { name: "Malawi", value: "MW" },
  { name: "Malaysia", value: "MY" },
  { name: "Maldive", value: "MV" },
  { name: "Mali", value: "ML" },
  { name: "Malta", value: "MT" },
  { name: "Marocco", value: "MA" },
  { name: "Mauritania", value: "MR" },
  { name: "Maurizius", value: "MU" },
  { name: "Messico", value: "MX" },
  { name: "Moldova", value: "MD" },
  { name: "Monaco", value: "MC" },
  { name: "Mongolia", value: "MN" },
  { name: "Montenegro", value: "ME" },
  { name: "Montserrat", value: "MS" },
  { name: "Mozambico", value: "MZ" },
  { name: "Myanmar/Birmania", value: "MM" },
  { name: "Namibia", value: "NA" },
  { name: "Nauru", value: "NR" },
  { name: "Nepal", value: "NP" },
  { name: "Nicaragua", value: "NI" },
  { name: "Niger", value: "NE" },
  { name: "Nigeria", value: "NG" },
  { name: "Norvegia", value: "NO" },
  { name: "Nuova Caledonia", value: "NC" },
  { name: "Nuova Zelanda", value: "NZ" },
  { name: "Oman", value: "OM" },
  { name: "Paesi Bassi", value: "NL" },
  { name: "Pakistan", value: "PK" },
  { name: "Palau", value: "PW" },
  { name: "Palestina", value: "PS" },
  { name: "Panama", value: "PA" },
  { name: "Papua Nuova Guinea", value: "PG" },
  { name: "Paraguay", value: "PY" },
  { name: "Perù", value: "PE" },
  { name: "Polinesia Francese", value: "PF" },
  { name: "Polonia", value: "PL" },
  { name: "Portogallo", value: "PT" },
  { name: "Qatar", value: "QA" },
  { name: "Regno Unito", value: "GB" },
  { name: "Repubblica Ceca", value: "CZ" },
  { name: "Repubblica Centrafricana", value: "CF" },
  { name: "Repubblica Democratica del Congo", value: "CD" },
  { name: "Repubblica Dominicana", value: "DO" },
  { name: "Romania", value: "RO" },
  { name: "Ruanda", value: "RW" },
  { name: "Russia", value: "RU" },
  { name: "Sahara Occidentale", value: "EH" },
  { name: "Saint Kitts e Nevis", value: "KN" },
  { name: "Saint Pierre e Miquelon", value: "PM" },
  { name: "Saint Vincent e Grenadine", value: "VC" },
  { name: "Saint-Barthélemy", value: "BL" },
  { name: "Saint-Martin (FR)", value: "MF" },
  { name: "Samoa", value: "WS" },
  { name: "San Marino", value: "SM" },
  { name: "Sant'Elena", value: "SH" },
  { name: "Santa Lucia", value: "LC" },
  { name: "Sao Tomé e Principe", value: "ST" },
  { name: "Sark", value: "GG" },
  { name: "Senegal", value: "SN" },
  { name: "Serbia", value: "RS" },
  { name: "Seychelles", value: "SC" },
  { name: "Sierra Leone", value: "SL" },
  { name: "Singapore", value: "SG" },
  { name: "Sint Maarten (NL)", value: "SX" },
  { name: "Siria", value: "SY" },
  { name: "Slovacchia", value: "SK" },
  { name: "Slovenia", value: "SI" },
  { name: "Somalia", value: "SO" },
  { name: "Spagna", value: "ES" },
  { name: "Sri Lanka", value: "LK" },
  { name: "Stati Federati di Micronesia", value: "FM" },
  { name: "Stati Uniti d'America", value: "US" },
  { name: "Sud Sudan", value: "SS" },
  { name: "Sudafrica", value: "ZA" },
  { name: "Sudan", value: "SD" },
  { name: "Suriname", value: "SR" },
  { name: "Svezia", value: "SE" },
  { name: "Svizzera", value: "CH" },
  { name: "Tagikistan", value: "TJ" },
  { name: "Taiwan", value: "TW" },
  { name: "Tanzania", value: "TZ" },
  { name: "Thailandia", value: "TH" },
  { name: "Timor Est", value: "TL" },
  { name: "Togo", value: "TG" },
  { name: "Tonga", value: "TO" },
  { name: "Trinidad e Tobago", value: "TT" },
  { name: "Tunisia", value: "TN" },
  { name: "Turchia", value: "TR" },
  { name: "Turkmenistan", value: "TM" },
  { name: "Tuvalu", value: "TV" },
  { name: "Ucraina", value: "UA" },
  { name: "Uganda", value: "UG" },
  { name: "Ungheria", value: "HU" },
  { name: "Uruguay", value: "UY" },
  { name: "Uzbekistan", value: "UZ" },
  { name: "Vanuatu", value: "VU" },
  { name: "Venezuela", value: "VE" },
  { name: "Vietnam", value: "VN" },
  { name: "Wallis e Futuna", value: "WF" },
  { name: "Yemen", value: "YE" },
  { name: "Zambia", value: "ZM" },
  { name: "Zimbabwe", value: "ZW" },
];
