import { ClassConstructor, ClassTransformOptions, plainToClass } from "class-transformer";

export class Mapper {
  private static _DEFAULT_OPTIONS: ClassTransformOptions = {
    excludeExtraneousValues: true,
    exposeDefaultValues: true,
  };

  static mapData<DestinationClass, SourceData>(
    className: ClassConstructor<DestinationClass>,
    source: SourceData,
    options?: ClassTransformOptions
  ): DestinationClass {
    const mappedData = plainToClass<DestinationClass, SourceData>(className, source, {
      ...this._DEFAULT_OPTIONS,
      ...options,
    });
    return mappedData;
  }

  static mapArrayData<DestinationClass, SourceData>(
    className: ClassConstructor<DestinationClass>,
    source: SourceData[],
    options?: ClassTransformOptions
  ): DestinationClass[] {
    const mappedArrayData = source.map(function mapToSingle(item: SourceData): DestinationClass {
      return Mapper.mapData(className, item, options);
    });

    return mappedArrayData;
  }
}
