import moment from "moment";
const year = moment().year();

const redditi = {
  name: "redditi",
  title: "Redditi",
  iconUrl: "/images/give-money.svg",
  sections: [
    {
      name: "Redditi",
      forms: [
        {
          stepname: "9",
          subtitle: [
            `%Nome%, tutte le prossime domande riguarderanno i redditi percepiti nel [year-2] perché ci servono a simulare il tuo valore ISEE. Se oggi la situazione è cambiata (tipo che hai perso il lavoro) te lo chiederemo più avanti.`,
          ],
          guided: [
            "L'ISEE è un indicatore usato per valutare se si hanno i requisiti per determinati servizi, bonus o agevolazioni pubbliche.",
          ],
          nextstep: `10n`,
          fields: [],
        },
        {
          stepname: "10n",
          subtitle: [
            `Nel [year-2] almeno un componente del tuo nucleo ha avuto redditi da lavoro dipendente (impiegato in azienda, per dire) o altri redditi assimilati ai fini fiscali?`,
          ],
          guided: [
            'I "redditi da lavoro dipendente" sono, ad esempio: stipendi, collaborazioni a progetto, borse di studio, compensi per lavori socialmente utili o come amministratore di società.',
          ],
          yesno: true,
          nextstep: [
            "switch:[",
            '{"eval":"response == true && v[\'Membrinucleo\']>1", "step":"10n_a"},',
            '{"eval":"response == true && v[\'Membrinucleo\']<=1", "step":"10n_b"},',
            '{"eval":"response == false", "step":"10n_c"}',
            "]",
          ],
          fields: [
            {
              dependencies: ["Membrinucleo"],
              name: `LavoroDipendente`,
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "10n_a",
          subtitle: ["Quanti?"],
          nextstep: "10n_b",
          fields: [
            {
              name: "NumLavoroDipendente",
              dependencies: ["Membrinucleo", `LavoroDipendente`],
              type: "number",
              validation: "eval",
              expression:
                "!isNaN(v['NumLavoroDipendente']) && v['NumLavoroDipendente'] > 0 && v['NumLavoroDipendente'] <= v['Membrinucleo']",
              default: 0,
              errorText:
                "eval:isNaN(v['NumLavoroDipendente']):Inserisci un numero corretto:Il numero di componenti che ha avuto redditi da lavoro dipendente o assimilati deve essere compreso tra 1 e il numero totale dei componenti del nucleo familiare",
              optional: false,
            },
          ],
        },
        {
          stepname: "10n_b",
          subtitle: ["%Nome%, questa è pesante: riesci a dirci il totale delle entrate da questo tipo di redditi?"],
          nextstep: "10n_c",
          guided: [
            "Intendiamo il totale delle entrate del nucleo familiare derivanti da lavoro dipendente o assimilati (stipendi, collaborazioni a progetto, borse di studio, lavori socialmente utili e altri redditi assimilati percepiti nel [year-2]).",
            "Puoi trovare questi valori nelle Certificazioni Uniche (CU [year-1]), che sono relative ai redditi [year-2], rilasciate dalle aziende per cui hai lavorato. Ad esempio, puoi trovare i valori nel riquadro della CU [year-1]: n. 1 per i redditi da lavoro dipendente a tempo indeterminato, n. 2 per i redditi da lavoro dipendente a tempo determinato, n. 4 per altri redditi assimilati a quelli da lavoro dipendente.",
          ],
          fields: [
            {
              dependencies: ["Membrinucleo", `LavoroDipendente`, "NumLavoroDipendente"],
              name: `TotRedditi2anno${year}`,
              placeholder: "Importo",
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['TotRedditi2anno${year}']) && v['TotRedditi2anno${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10n_c",
          subtitle: [
            "Nel [year-2] uno o più componenti del tuo nucleo hanno avuto pensioni o trattamenti assistenziali, previdenziali e indennitari, esclusi quelli per disabilità e invalidità?",
          ],
          guided: [
            "Esempi: pensione di vecchiaia, cassa integrazione, NASpI, ANF e carta acquisti.",
            "Occhio: NON vanno incluse le somme percepite per disabilità e invalidità e il Reddito di Cittadinanza perché non servono per il calcolo del valore ISEE.",
          ],
          yesno: true,
          nextstep:
            'switch:[{"eval":"response == true && v[\'Membrinucleo\']>1", "step":"10n_d"}, {"eval":"response == true && v[\'Membrinucleo\']<=1", "step":"10n_e"}, {"eval":"response == false", "step":"10n_f"}]',
          fields: [
            {
              dependencies: ["Membrinucleo"],
              name: `Pensione`,
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "10n_d",
          subtitle: "Quanti?",
          nextstep: "10n_e",
          fields: [
            {
              name: "NumPensione",
              dependencies: ["Membrinucleo", `Pensione`],
              validation: "eval",
              expression: "!isNaN(v['NumPensione']) && v['NumPensione'] > 0 && v['NumPensione'] <= v['Membrinucleo']",
              errorText:
                "eval:isNaN(v['NumPensione']):Inserisci un numero corretto:Il numero di componenti che ha avuto redditi da pensione o assimilati deve essere compreso tra 1 e il numero totale dei componenti del nucleo familiare",
              type: "number",
              default: 0,
              optional: false,
            },
          ],
        },
        {
          stepname: "10n_e",
          subtitle: "Tieni duro: riesci a dirci il totale di queste entrate?",
          guided: [
            "NON vanno incluse le somme percepite per:",
            "• disabilità e invalidità, perché sono considerate un recupero dello svantaggio sociale;",
            "• Assegno Unico; ",
            "• Reddito o Pensione di Cittadinanza, Supporto per la Formazione e il Lavoro e Assegno di Inclusione (ai fini del calcolo dei requisiti ISEE per il Supporto per la Formazione e il Lavoro e per l’Assegno di Inclusione).",
            "",
            "Puoi trovare questi valori nelle Certificazioni Uniche [year-1] (CU [year-1]), relative ai redditi [year-2], rilasciate dall'INPS o da altri enti che hanno pagato le pensioni o le indennità. In particolare in questi riquadri della CU [year-1]: n. 2 per la cassa integrazione e l'indennità di disoccupazione NASpI, n. 3 per i redditi di pensione.",
          ],
          nextstep: "10n_f",
          fields: [
            {
              name: `TotPensione${year}`,
              dependencies: ["Membrinucleo", `Pensione`, "NumPensione"],
              type: "currency",
              placeholder: "Importo",
              validation: "eval",
              expression: `!isNaN(v['TotPensione${year}']) && v['TotPensione${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10n_f",
          subtitle: `Nel [year-2] uno o più componenti del tuo nucleo hanno ricevuto assegni periodici da parte dell'ex coniuge per il mantenimento suo e/o dei figli?`,
          guided: [
            "Si tratta degli assegni di mantenimento in seguito a separazione legale ed effettiva o allo scioglimento o annullamento del matrimonio.",
          ],
          yesno: true,
          nextstep: "10n_g:10n_h",
          fields: [
            {
              dependencies: ["Membrinucleo", "Figli", "Figliminori", "Genitoresolo"],
              name: "Assegniperiodici",
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "10n_g",
          subtitle: "Qual è l'importo totale di questi assegni?",
          guided: [
            "Puoi trovare questi valori:",
            "• nelle Certificazioni Uniche [year-1] (CU [year-1]), che sono relative ai redditi [year-2], nel riquadro n. 5 (assegni periodici corrisposti dal coniuge);",
            "• nel modello 730 [year-1], relativo ai redditi [year-2], nel quadro C, sezione II, righi da C6 a C8;",
            "• nel modello REDDITI Persone Fisiche [year-1], relativo ai redditi [year-2], nel quadro RC, sezione II, righi RC7 e RC8.",
          ],
          nextstep: "10n_h",
          fields: [
            {
              dependencies: ["Membrinucleo", "Figli", "Figliminori", "Genitoresolo", "Assegniperiodici"],
              name: `TotAssegniPeriodici${year}`,
              placeholder: "Importo",
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['TotAssegniPeriodici${year}']) && v['TotAssegniPeriodici${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10n_h",
          subtitle: "Nel [year-2] uno o più componenti del tuo nucleo hanno avuto altri redditi?",
          guided: [
            "Esempi: redditi da lavoro autonomo (tipo in partita IVA), redditi soggetti a ritenuta d'imposta (ad esempio, vincite da giochi di abilità o da concorsi a premi), redditi esenti o maturati all'estero e affitti. I dividendi sono le parti di profitto che un'azienda decide di distribuire agli azionisti, ti riguarda solo se hai delle quote in una società.",
          ],
          yesno: true,
          nextstep: "10n_i:10n_2",
          fields: [
            {
              dependencies: ["Membrinucleo"],
              name: "AltriRedditi",
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "10n_i",
          subtitle: "Il totale di questi redditi?",
          guided: [
            "Intendiamo il totale delle entrate del nucleo familiare per redditi da lavoro autonomo, redditi soggetti a ritenuta d'imposta (ad esempio, vincite da giochi di abilità o da concorsi a premi), redditi esenti o maturati all'estero, affitti e simili.",
            "Ad esempio, per i redditi da lavoro autonomo puoi trovare questi valori:",
            "• nel modello 730 [year-1], relativo ai redditi [year-2], nel quadro D, sezione I, righi D3 e D5 o nel modello REDDITI Persone Fisiche [year-1], relativo ai redditi [year-2], nel quadro RL, righi RL14, RL15, RL16, RL25, Rl26, RL27, per i redditi di lavoro autonomo per i quali non è richiesta la partita IVA;",
            "• nel modello REDDITI Persone Fisiche [year-1], relativo ai redditi [year-2], nel quadro RE, rigo RE23, per i lavoratori autonomi titolari di Partita IVA che non sono in regime forfettario;",
            "• nel modello REDDITI Persone Fisiche [year-1], relativo ai redditi [year-2], nel quadro LM, sezione II, rigo LM34, colonna 3, per i lavoratori autonomi titolari di Partita IVA in regime forfettario.",
          ],
          nextstep: "10n_2",
          fields: [
            {
              dependencies: ["Membrinucleo", "AltriRedditi"],
              name: `TotAltriRedditi${year}`,
              type: "currency",
              placeholder: "Importo",
              validation: "eval",
              expression: `!isNaN(v['TotAltriRedditi${year}']) && v['TotAltriRedditi${year}']>=1`,
              errorText: "Il numero deve essere uguale o maggiore di 1",
              optional: false,
            },
          ],
        },
        {
          stepname: "10n_2",
          subtitle:
            "Dai che ci siamo quasi: nel [year-2] almeno un componente del tuo nucleo ha versato assegni periodici per il mantenimento di figli o ex coniuge esterni al tuo nucleo?",
          guided: [
            "Si tratta degli assegni periodici versati per il mantenimento dei figli o del coniuge in seguito a separazione legale ed effettiva o allo scioglimento o annullamento del matrimonio.",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"response == true", "step":"10n_3"},',
            `{"eval":"response == false && v[\'LavoroDipendente\'] == true", "step":"1A"},`,
            `{"eval":"response == false && v[\'LavoroDipendente\'] == false && v[\'AltriRedditi\'] == true", "step":"1B"},`,
            `{"eval":"response == false && v[\'LavoroDipendente\'] == false && v[\'AltriRedditi\'] == false && v[\'Pensione\'] == true", "step":"1C"},`,
            `{"eval":"response == false && v[\'LavoroDipendente\'] == false && v[\'AltriRedditi\'] == false && v[\'Pensione\'] == false", "step":"end"}`,
            "]",
          ],
          yesno: true,
          fields: [
            {
              dependencies: ["Membrinucleo", "Figli", "Figliminori", "Genitoresolo"],
              name: `AssegniPeriodiciVersati`,
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "10n_3",
          subtitle: "Il totale di questi assegni?",
          guided: [
            "Intendiamo il totale degli assegni periodici versati per il mantenimento dei figli e dell'ex coniuge.",
            "Puoi trovare questi valori:",
            "• nel modello 730 [year-1], relativo ai redditi [year-2], nel quadro E, sezione II, rigo E22;",
            "• nel modello REDDITI Persone Fisiche [year-1], relativo ai redditi [year-2], nel quadro RP, sezione II, rigo RP 22.",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v[\'LavoroDipendente\'] == true", "step":"1A"},',
            '{"eval":"v[\'LavoroDipendente\'] == false && v[\'AltriRedditi\'] == true", "step":"1B"},',
            '{"eval":"v[\'LavoroDipendente\'] == false && v[\'AltriRedditi\'] == false && v[\'Pensione\'] == true", "step":"1C"},',
            '{"eval":"v[\'LavoroDipendente\'] == false && v[\'AltriRedditi\'] == false && v[\'Pensione\'] == false", "step":"end"}',
            "]",
          ],
          fields: [
            {
              dependencies: ["Membrinucleo", "Figli", "Figliminori", "Genitoresolo", "AssegniPeriodiciVersati"],
              name: `TotAssegniPeriodiciVersati${year}`,
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['TotAssegniPeriodiciVersati${year}']) && value.length > 0 && v['TotAssegniPeriodiciVersati${year}']>=0`,
              errorText: "Il numero deve essere uguale o maggiore di 0",
              placeholder: "Importo",
              optional: false,
            },
          ],
        },
        {
          stepname: "1A",
          subtitle:
            "Uno o più componenti del tuo nucleo con lavoro dipendente a tempo indeterminato nel corso del [year-2] ha subito la sospensione, la riduzione o la perdita del lavoro?",
          guided: [
            "Esempio: nel [year-2], un componente del nucleo familiare era assunto a tempo indeterminato come operaio. Nel [year-1], l'azienda è stata costretta a metterlo in cassa integrazione. ",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"response == true", "step":"2A"},',
            `{"eval":"response == false && v['LavoroDipendente'] == true", "step":"1B"},`,
            '{"eval":"response == false && v[\'AltriRedditi\'] == true", "step":"1B"},',
            '{"eval":"response == false && v[\'AltriRedditi\'] == false && v[\'Pensione\'] == true", "step":"1C"},',
            '{"eval":"response == false && v[\'AltriRedditi\'] == false && v[\'Pensione\'] == false", "step":"end"}',
            "]",
          ],
          yesno: true,
          fields: [
            {
              dependencies: ["Membrinucleo", "LavoroDipendente", "NumLavoroDipendente"],
              name: "RiduzioneLavoroDipendente",
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "1B",
          subtitle:
            "Uno o più componenti del tuo nucleo con un lavoro diverso da quello dipendente a tempo indeterminato nel corso del [year-2] ha perso il lavoro?",
          guided: [
            "Esempio: nel [year-2], un componente del nucleo familiare era assunto con un contratto a tempo determinato in una pasticceria e il contratto non è stato rinnovato nel [year-1]. ",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"v[\'Pensione\'] == true", "step":"1C"},',
            '{"eval":"v[\'Pensione\'] == false && v[\'AltriRedditi\'] == false && response == true", "step":"2B"},',
            '{"eval":"v[\'Pensione\'] == false && v[\'AltriRedditi\'] == true && response == true", "step":"2B"},',
            '{"eval":"v[\'Pensione\'] == false && v[\'AltriRedditi\'] == true && response == false", "step":"1D"},',
            '{"eval":"v[\'Pensione\'] == false && v[\'AltriRedditi\'] == false && response == false", "step":"1D"}',
            "]",
          ],
          yesno: true,
          fields: [
            {
              dependencies: [
                "Membrinucleo",
                "LavoroDipendente",
                "NumLavoroDipendente",
                "AltriRedditi",
                "RiduzioneLavoroDipendente",
              ],
              name: "PerditaLavoroDipendente",
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "1C",
          subtitle:
            "%Nome%, idolo delle folle se rispondi a questa: uno o più componenti del tuo nucleo hanno subito un’interruzione dei trattamenti previdenziali, assistenziali e indennitari NON IRPEF che ricevevano nel [year-2]?",
          guided: [
            "Esempio: nel [year-2] un componente del tuo nucleo familiare ha percepito il Reddito di cittadinanza e poi ne ha perso il diritto.",
            "",
            "I trattamenti previdenziali, assistenziali e indennitari NON IRPEF (imposta sui redditi che normalmente si pagano allo Stato quando si ha uno stipendio o la pensione) sono, ad esempio: il Reddito di cittadinanza, la Carta Acquisti, l’Assegno per il Nucleo Familiare e l’Assegno di Maternità dei Comuni.",
          ],
          nextstep: [
            "switch:[",
            '{"eval":"response == true", "step":"2A"},',
            '{"eval":"v[\'PerditaLavoroDipendente\'] == false || v[\'PerditaLavoroDipendente\'] == null", "step":"1D"},',
            '{"eval":"v[\'PerditaLavoroDipendente\'] == true", "step":"2B"}',
            "]",
          ],
          yesno: true,
          fields: [
            {
              dependencies: [
                "Membrinucleo",
                "LavoroDipendente",
                "NumLavoroDipendente",
                "NumPensione",
                "Pensione",
                "RiduzioneLavoroDipendente",
              ],
              name: "RiduzionePrevidenziali",
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "1D",
          subtitle:
            "Qui sei Einstein: ti risulta che il reddito complessivo del tuo nucleo sia diminuito di più del 25% rispetto al [year-2]?",
          guided: [
            "Esempio: nel [year-2], il tuo nucleo familiare aveva un reddito totale di 20.000 € all'anno, ma negli ultimi 12 mesi il reddito totale è sceso a 13.000 €.",
          ],
          nextstep: "2B:end",
          yesno: true,
          fields: [
            {
              dependencies: [
                "Membrinucleo",
                "LavoroDipendente",
                "NumLavoroDipendente",
                "AltriRedditi",
                "Pensione",
                "NumPensione",
                "RiduzioneLavoroDipendente",
                "PerditaLavoroDipendente",
                "RiduzionePrevidenziali",
              ],
              name: "RiduzioneReddito25",
              type: "yesno",
              validation: "has",
              optional: false,
            },
          ],
        },
        {
          stepname: "2A",
          subtitle:
            "Qual è il totale dei redditi da lavoro dipendente e dei redditi assimilati ai fini fiscali negli ultimi 2 mesi?",
          guided: [
            "I redditi da lavoro dipendente e assimilati sono, ad esempio, stipendi, collaborazioni a progetto, borse di studio e lavori socialmente utili.",
          ],
          nextstep: "2A_b",
          fields: [
            {
              dependencies: [
                "Membrinucleo",
                "LavoroDipendente",
                "NumLavoroDipendente",
                "Pensione",
                "NumPensione",
                "RiduzioneLavoroDipendente",
                "RiduzionePrevidenziali",
              ],
              name: `TotRedditi2Mesi${year}`,
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['TotRedditi2Mesi${year}']) && value.length > 0 && v['TotRedditi2Mesi${year}']>=0`,
              errorText: "Il numero deve essere uguale o maggiore di 0",
              placeholder: "Importo",
              optional: false,
            },
          ],
        },
        {
          stepname: "2A_b",
          subtitle:
            "Qual è il totale di pensioni o trattamenti assistenziali, previdenziali e indennitari (esclusi quelli per disabilità e invalidità) degli ultimi 2 mesi?",
          guided: [
            "Intendiamo pensioni di vecchiaia, cassa integrazione, NASpI e altri trattamenti assistenziali, previdenziali e indennitari. NON vanno incluse le somme percepite per:",
            "• disabilità e invalidità, perché sono considerate un recupero dello svantaggio sociale;",
            "• Assegno Unico;",
            "• far fronte a spese sostenute e rendicontate (come il Bonus Asilo Nido);",
            "• Supporto per la Formazione e il Lavoro e Assegno di Inclusione.",
          ],
          nextstep: "2A_c",
          fields: [
            {
              dependencies: [
                "Membrinucleo",
                "LavoroDipendente",
                "NumLavoroDipendente",
                "Pensione",
                "NumPensione",
                "RiduzioneLavoroDipendente",
                "RiduzionePrevidenziali",
              ],
              name: `TotPensione2Mesi${year}`,
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['TotPensione2Mesi${year}']) && value.length > 0 && v['TotPensione2Mesi${year}']>=0`,
              errorText: "Il numero deve essere uguale o maggiore di 0",
              placeholder: "Importo",
              optional: false,
            },
          ],
        },
        {
          stepname: "2A_c",
          subtitle: "Qual è il totale dei redditi da attività d'impresa o lavoro autonomo negli ultimi 2 mesi?",
          guided: [
            "In linea generale, sono lavoratori autonomi i liberi professionisti, gli artigiani, i soci di cooperativa o agenti e rappresentanti di commercio e diverse tipologie di lavoratori in partita IVA, come consulenti marketing e sviluppatori software.",
            "L'attività d'impresa è quell'attività economica che ha come obiettivo la produzione o lo scambio di beni o servizi, ad esempio, quella svolta da una macelleria o da un e-commerce.",
          ],
          nextstep: "end",
          fields: [
            {
              dependencies: [
                "Membrinucleo",
                "LavoroDipendente",
                "NumLavoroDipendente",
                "Pensione",
                "NumPensione",
                "RiduzioneLavoroDipendente",
                "RiduzionePrevidenziali",
              ],
              name: `TotAltriRedditi2Mesi${year}`,
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['TotAltriRedditi2Mesi${year}']) && value.length > 0 && v['TotAltriRedditi2Mesi${year}']>=0`,
              errorText: "Il numero deve essere uguale o maggiore di 0",
              placeholder: "Importo",
              optional: false,
            },
          ],
        },
        {
          stepname: "2B",
          subtitle:
            "Qual è il totale dei redditi da lavoro dipendente e dei redditi assimilati ai fini fiscali negli ultimi 12 mesi?",
          guided: [
            "I redditi da lavoro dipendente e assimilati sono, ad esempio, stipendi, collaborazioni a progetto, borse di studio e lavori socialmente utili.",
          ],
          nextstep: "2B_b",
          fields: [
            {
              dependencies: [
                "Membrinucleo",
                "LavoroDipendente",
                "NumLavoroDipendente",
                "AltriRedditi",
                "Pensione",
                "NumPensione",
                "RiduzioneLavoroDipendente",
                "PerditaLavoroDipendente",
                "RiduzionePrevidenziali",
                "RiduzioneReddito25",
              ],
              name: `TotRedditi12Mesi${year}`,
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['TotRedditi12Mesi${year}']) && value.length > 0 && v['TotRedditi12Mesi${year}']>=0`,
              errorText: "Il numero deve essere uguale o maggiore di 0",
              placeholder: "Importo",
              optional: false,
            },
          ],
        },
        {
          stepname: "2B_b",
          subtitle:
            "Qual è il totale di pensioni o trattamenti assistenziali, previdenziali e indennitari (esclusi quelli per disabilità e invalidità) negli ultimi 12 mesi?",
          guided: [
            "Intendiamo pensioni di vecchiaia, cassa integrazione, NASpI e altri trattamenti assistenziali, previdenziali e indennitari. NON vanno incluse le somme percepite per:",
            "• disabilità e invalidità, perché sono considerate un recupero dello svantaggio sociale;",
            "• Assegno Unico;",
            "• far fronte a spese sostenute e rendicontate (come il Bonus Asilo Nido);",
            "• Reddito o Pensione di Cittadinanza, Supporto per la Formazione e il Lavoro e Assegno di Inclusione (ai fini del calcolo dei requisiti ISEE per il Supporto per la Formazione e il Lavoro e per l’Assegno di Inclusione).",
          ],
          nextstep: "2B_c",
          fields: [
            {
              dependencies: [
                "Membrinucleo",
                "LavoroDipendente",
                "NumLavoroDipendente",
                "AltriRedditi",
                "Pensione",
                "NumPensione",
                "RiduzioneLavoroDipendente",
                "PerditaLavoroDipendente",
                "RiduzionePrevidenziali",
                "RiduzioneReddito25",
              ],
              name: `TotPensione12Mesi${year}`,
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['TotPensione12Mesi${year}']) && value.length > 0 && v['TotPensione12Mesi${year}']>=0`,
              errorText: "Il numero deve essere uguale o maggiore di 0",
              placeholder: "Importo",
              optional: false,
            },
          ],
        },
        {
          stepname: "2B_c",
          subtitle: "Qual è il totale dei redditi da attività d'impresa o lavoro autonomo negli ultimi 12 mesi?",
          guided: [
            "In linea generale, sono lavoratori autonomi i liberi professionisti, gli artigiani, i soci di cooperativa o agenti e rappresentanti di commercio e diverse tipologie di lavoratori in partita IVA, come consulenti marketing e sviluppatori software.",
            "L'attività d'impresa è quell'attività economica che ha come obiettivo la produzione o lo scambio di beni o servizi, ad esempio, quella svolta da una macelleria o da un e-commerce.",
          ],
          nextstep: "end",
          fields: [
            {
              dependencies: [
                "Membrinucleo",
                "LavoroDipendente",
                "NumLavoroDipendente",
                "NumPensione",
                "AltriRedditi",
                "Pensione",
                "RiduzioneLavoroDipendente",
                "PerditaLavoroDipendente",
                "RiduzionePrevidenziali",
                "RiduzioneReddito25",
              ],
              name: `TotAltriRedditi12Mesi${year}`,
              type: "currency",
              validation: "eval",
              expression: `!isNaN(v['TotAltriRedditi12Mesi${year}']) && value.length > 0 && v['TotAltriRedditi12Mesi${year}']>=0`,
              errorText: "Il numero deve essere uguale o maggiore di 0",
              placeholder: "Importo",
              optional: false,
            },
          ],
        },
      ],
    },
  ],
};

export default redditi;
