import { atom } from "jotai";
import { computeDiscount } from "../services/shoppingCart";
import { CartDiscount, CartDiscountStoreInterface } from "./cartDiscountStore";
import { CartAtom, CartItems } from "./cartStore";

interface ICartAndDiscount {
  cart: CartItems;
  discount: CartDiscountStoreInterface;
}

export const ShoppingCartAndDiscount = atom(
  (get) => {
    const cart = get(CartAtom);
    const discount = get(CartDiscount);

    return { cart, discount };
  },
  async (get, set, { cart, discount: newDiscount }: ICartAndDiscount) => {
    const oldDiscount = get(CartDiscount);
    set(CartDiscount, { ...oldDiscount, loading: true });

    const { cart: newCart, discount } = await computeDiscount(newDiscount.discountCode, cart, newDiscount);
    set(CartAtom, newCart);
    set(CartDiscount, { ...discount, loading: false });
  }
);
