export const DISCOUNT_NOT_VALID = "discountNotValid";
export const SERVICE_WITHOUT_DISCOUNT_CODE = "seviceWithoutDiscountCode";

export function mapErrorMessage(message?: string) {
  switch (message) {
    case "discountReachedMaximumPerUserUsages":
      return "Hai superato il limite di utilizzo per questo codice sconto.";
    case "discountReachedMaximumGlobalUsages":
      return "Il limite di utilizzo del codice sconto è stato raggiunto.";
    case DISCOUNT_NOT_VALID:
      return "Codice sconto non valido.";
    case "thisDiscountDoesntExist":
      return "Codice sconto non valido.";
    case "invalidDiscountDateInterval":
      return "Offerta terminata.";
    case SERVICE_WITHOUT_DISCOUNT_CODE:
      return "Questi servizi non sono coperti dal codice sconto.";
    default:
      return message && message?.length > 0 ? message : "Codice sconto non valido.";
  }
}
