import { DISCOUNT_STORAGE_INDEX } from "../stores/cartDiscountStore";
import { CART_STORAGE_INDEX } from "../stores/cartStore";

export const localStorageSelectiveClean = (): void => {
  const doNotRemove: string[] = [CART_STORAGE_INDEX, DISCOUNT_STORAGE_INDEX];
  Object.keys(localStorage)
    .filter((key) => !doNotRemove.includes(key))
    .forEach((key) => {
      console.debug("removed", key);
      localStorage.removeItem(key);
    });
};
