import { DOM_READY_EVENT } from "../common/env";

export const domReadyPromise = () => {
  switch (document.readyState) {
    case "interactive":
    case "complete":
      return new Promise((resolve) => setTimeout(() => resolve(document.readyState), 1));
  }

  return new Promise((resolve) =>
    document.addEventListener(`${DOM_READY_EVENT}`, () => setTimeout(() => resolve(document.readyState), 1))
  );
};

export default domReadyPromise;
