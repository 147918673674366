import { Background } from "@bonusx-global/bx-design-system";
import { outlinedInputClasses } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { default as R, font, default as palette, size, weight } from "../R";
import { hexWithOpacity } from "../utils/hexWithOpacity";

const primary = {
  main: palette.colors.vividBlue,
  light: palette.colors.secondaryBlue,
};

const secondary = {
  main: palette.colors.vividGreen,
  light: palette.colors.secondaryGreen,
};

const error = {
  main: palette.colors.vividRed,
  dark: palette.colors.errorRed,
};

const text = {
  primary: palette.colors.deepBlue,
  secondary: palette.colors.smoothBlack,
  hint: palette.colors.mediumGray,
  disabled: palette.colors.disabledGray,
};

const warning = {
  main: palette.colors.primaryOrange,
  light: palette.colors.secondaryOrange,
};

const background = {
  default: Background.default,
};

const citizenTheme = createTheme({
  palette: {
    primary,
    secondary,
    text,
    error,
    warning,
    background,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: palette.colors.disabledGray,
            "-webkit-text-fill-color": `${palette.colors.disabledGray}`,
          },
          [`& ${outlinedInputClasses.notchedOutline}`]: {
            borderColor: palette.colors.errorRed,
          },
          "&.Mui-error": {
            borderColor: palette.colors.errorRed,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            color: palette.colors.errorRed,
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          color: "blue",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
          minWidth: "1.65rem",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "5px !important",
          border: `1px solid ${palette.colors.mediumGray}`,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.colors.vividBlue,
          textDecoration: "none",
          fontWeight: 600,
          fontSize: 12,
          cursor: "pointer",
          lineHeight: "14.4px",
          "&:hover": {
            textDecoration: "none",
          },
          "&:focus": {
            textDecoration: "none",
          },
          "&:active": {
            textDecoration: "none",
          },
          "&:visited": {
            textDecoration: "none",
          },
          "&:link": {
            textDecoration: "none",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          pointerEvents: "all",
          backgroundColor: palette.colors.deepBlue,
          padding: 18,
          borderRadius: 20,
          background: `linear-gradient(90deg, ${R.colors.deepBlue} 0%, ${R.colors.darkBlue} 100%)`,
        },
        arrow: {
          color: palette.colors.deepBlue,
        },
        popper: {
          zIndex: 1100,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        determinate: {
          backgroundColor: palette.colors.mediumGray,
          zIndex: -1,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: 45,
          borderRadius: 30,
          padding: "10px 20px",
          fontWeight: weight.fontWeight.regular,
          textTransform: "uppercase",
          "&.Mui-disabled": {
            color: R.colors.white,
            background: palette.colors.disabledGray,
            boxShadow: "0px 14px 23px rgba(28, 37, 44, 0.08)",
          },
        },
        contained: {
          padding: "10px 20px",
          boxShadow: "none",
          borderRadius: "24.4664px",
          fontWeight: weight.fontWeight.bold,
          fontSize: size.fontSize.medium,
          height: 40,
          lineHeight: "14.4px",
          background: palette.colors.gradientLightBlue,
          backgroundColor: palette.colors.gradientLightBlue,
          color: palette.colors.white,
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "rgb(131,165,239,0.30)",
          },
        },
        outlined: {
          padding: "10px 20px",
          minWidth: 90,
          boxShadow: "0px 14px 23px rgba(28, 37, 44, 0.08)",
          fontWeight: weight.fontWeight.bold,
          fontSize: 14,
          height: 40,
          color: palette.colors.vividBlue,
          borderWidth: "1.5px",
          borderColor: palette.colors.secondaryBlue,
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        },
        text: {
          fontSize: size.fontSize.medium,
          lineHeight: "16.8px",
          fontWeight: weight.fontWeight.bold,
          textTransform: "initial",
          color: palette.colors.smoothBlack,
          padding: "13px 30px",
          boxShadow: "0px 14px 23px rgba(28, 37, 44, 0.08)",
          "&.Mui-disabled": {
            backgroundColor: palette.colors.disabledGray,
            boxShadow: "0px 14px 23px rgba(28, 37, 44, 0.08)",
            color: `${palette.colors.white} !important`,
            fontWeight: weight.fontWeight.bold,
            fontSize: size.fontSize.medium,
            lineHeight: "120%",
          },
        },
        containedPrimary: {
          background: `linear-gradient(90deg, ${palette.colors.gradientColorPrimaryOne} 0%, ${palette.colors.gradientColorPrimarySecond} 100%)`,
          "&:hover": {
            boxShadow: `5px 15px 30px rgb(9,64,116,0.3)`,
          },
          "&.Mui-disabled": {
            background: palette.colors.disabledGray,
            opacity: 1,
            color: "white !important",
          },
        },
        containedSecondary: {
          color: "white",
          "&:hover": {
            boxShadow: `5px 15px 30px ${palette.colors.mediumSeaGreen}`,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            color: palette.colors.errorRed,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
        outlined: {
          backgroundColor: hexWithOpacity(palette.colors.white, 0.5),
          border: `2px solid ${hexWithOpacity(palette.colors.white, 0.5)}`,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.06)",
        },
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: "flex-end",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.colors.mediumGray,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          marginRight: 8,
          marginTop: 4,
          marginBottom: 4,
          backgroundColor: "transparent",
          color: palette.colors.errorRed,
          fontSize: size.fontSize.mediumSmall,
          fontWeight: weight.fontWeight.bold,
          textTransform: "uppercase",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: palette.colors.white,
          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.05)",
          color: palette.colors.smoothBlack,
          maxWidth: "80vw",
          width: palette.dimens.drawerWidth,
          "&.MuiDrawer-paperAnchorRight": {
            width: "100%",
            maxWidth: "none",
            height: "calc(100% - 60px)",
          },
        },
        paperAnchorDockedLeft: {
          borderRight: "0px",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          color: "red",
          backgroundColor: hexWithOpacity(palette.colors.mediumGray, 0.3),
          "&:hover": {
            backgroundColor: hexWithOpacity(palette.colors.mediumGray, 0.4),
          },
          "&.Mui-focused": {
            backgroundColor: hexWithOpacity(palette.colors.mediumGray, 0.5),
          },
        },
        input: {
          padding: "16px 12px 15px",
          fontSize: size.fontSize.medium,
          lineHeight: 1.6,
          color: palette.colors.vividBlue,
        },
        multiline: {
          padding: "16px 12px 15px",
        },
        underline: {
          "&::before, &::after": {
            border: 0,
          },
          "&:hover": {
            border: 0,
            "&::before": {
              border: 0,
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: palette.colors.deepBlue,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          marginBottom: 10,
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 5,
          fontWeight: weight.fontWeight.regular,
          "&:hover": {
            cursor: "pointer",
          },
          "&:focus ": {
            backgroundColor: `${palette.colors.gradientLightBlue} !important`,
            color: palette.colors.vividBlue,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: font.fontFamily,
          fontSize: size.fontSize.medium,
          fontWeight: weight.fontWeight.regular,
          letterSpacing: 0.1,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 36,
          color: palette.colors.black,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 12,
        },
        list: {
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 12,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: "normal",
          maxWidth: 700,
          fontWeight: weight.fontWeight.regular,
          "&:hover": {
            backgroundColor: palette.colors.gradientLightBlue,
            color: palette.colors.vividBlue,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: "rgba(0, 0, 0, 0)",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 20,
          padding: 16,
          margin: 8,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: palette.colors.vividRed,
          fontFamily: font.fontFamily,
          fontWeight: weight.fontWeight.bold,
          fontSize: size.fontSize.moreLarge,
          lineHeight: "28px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          height: 18.5,
          width: 18.5,
          border: "1.5px solid orange",
          borderRadius: 4,
          "& svg": {
            "& path": {
              opacity: 0,
              boxShadow: "0px 4px 8px rgba(28, 37, 44, 0.08)",
              color: palette.colors.mediumGray,
              d: 'path("")',
            },
          },
          "&.Mui-checked": {
            "&:hover": {
              backgroundColor: `${R.colors.vividGreen} !important`,
            },
            backgroundColor: `${R.colors.vividGreen}`,
            borderColor: `${R.colors.vividGreen}`,
            "& svg": {
              width: 4,
              height: 8,
              border: "1.5px solid white",
              borderTop: 0,
              borderLeft: 0,
              transform: "rotate(45deg)",
            },
          },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      "Sora",
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    allVariants: {
      fontFamily: font.fontFamily,
      fontWeight: 500,
    },
    h1: {
      fontFamily: font.fontFamily,
      fontWeight: weight.fontWeight.bold,
      fontSize: size.fontSize.extraLarge,
      lineHeight: "28.8px",
    },
    h2: {
      fontFamily: font.fontFamily,
      fontWeight: weight.fontWeight.bold,
      fontSize: size.fontSize.moreLarge,
      lineHeight: "28px",
    },
    h3: {
      fontFamily: font.fontFamily,
      fontSize: size.fontSize.large,
      fontWeight: weight.fontWeight.bold,
      lineHeight: "21.6px",
    },
    h4: {
      fontFamily: font.fontFamily,
      fontWeight: weight.fontWeight.bold,
      fontSize: size.fontSize.mediumLarge,
      lineHeight: "25.6px",
    },
    h6: {
      fontWeight: weight.fontWeight.bold,
    },
    subtitle1: {
      fontFamily: font.fontFamily,
      fontSize: size.fontSize.mediumSmall,
      fontWeight: weight.fontWeight.regular,
      lineHeight: "14px",
    },
    subtitle2: {
      fontFamily: font.fontFamily,
      fontSize: size.fontSize.small,
      fontWeight: weight.fontWeight.bold,
      lineHeight: "14px",
    },
    body1: {
      fontFamily: font.fontFamily,
      fontSize: size.fontSize.mediumLarge,
      fontWeight: weight.fontWeight.regular,
      lineHeight: "24px",
    },
    body2: {
      fontFamily: font.fontFamily,
      fontSize: size.fontSize.medium,
      fontWeight: weight.fontWeight.regular,
      lineHeight: "21px",
    },
    button: {
      fontFamily: font.fontFamily,
      fontSize: size.fontSize.medium,
      fontWeight: weight.fontWeight.bold,
      lineHeight: "16.8px",
    },
    caption: {
      fontFamily: font.fontFamily,
      fontSize: size.fontSize.mediumSmall,
      lineHeight: "150%",
    },
    overline: {
      fontFamily: font.fontFamily,
      fontSize: size.fontSize.mediumSmall,
      fontWeight: weight.fontWeight.bold,
      lineHeight: 1.4,
      letterSpacing: 1.5,
    },
  },
});

export default citizenTheme;
