import { EvaluationContext, OpenFeature } from "@openfeature/react-sdk";
import { APP_VERSION, PACKAGE_NAME } from "../../../common/env";
import { IFeatureFlagProviderFactory } from "../../../interfaces/feature-flag-provider-factory.interface";
import { ConfigCatProviderFactory } from "../../ConfigCatProviderFactory/config-cat-provider-factory";
import { IFeatureFlagContextAccountDataService } from "../context-account-data/feature-flag-context-account-data.interface";
import { FeatureFlagContextAccountDataService } from "../context-account-data/feature-flag-context-account-data.service";
import { IFeatureFlagService } from "./feature-flag.interface";

export class FeatureFlagService implements IFeatureFlagService {
  private readonly _PAGKAGE_NAME = PACKAGE_NAME;
  private readonly _APP_VERSION = APP_VERSION;

  constructor(
    private readonly _openFeature = OpenFeature,
    private readonly _featureFlagProviderFactory: IFeatureFlagProviderFactory = new ConfigCatProviderFactory(),
    private readonly _contextAccountDataService: IFeatureFlagContextAccountDataService = new FeatureFlagContextAccountDataService()
  ) {}

  async initialize(): Promise<IFeatureFlagService> {
    const featureFlagProvider = this._featureFlagProviderFactory.create();

    const context = this._getContext();
    await this._openFeature.setContext(context);
    await this._openFeature.setProviderAndWait(featureFlagProvider);

    return this;
  }

  public updateContext(): Promise<void> {
    const context = this._getContext();
    const setContextPromise = this._openFeature.setContext(context);

    return setContextPromise;
  }

  private _getContext(): EvaluationContext {
    const appVersion = this._APP_VERSION;
    const packageName = this._PAGKAGE_NAME;

    const accountData = this._contextAccountDataService.extractFromToken();
    return { packageName, appVersion, ...accountData };
  }
}
