import { PaymentMethods } from "bonusx-api-main-manager";
import { atomWithStorage } from "jotai/utils";
import { IProduct } from "../services/ApiClientBonusXServiceRepository/interfaces/IProduct.interface";

export type CartItem = {
  count: number;
  service: IProduct;
  discountPrice: number;
  discountCode: string;
};

export type CartItems = Array<CartItem>;

export const CART_STORAGE_INDEX = "bx-cart";
export const PAYMENT_METHOD_STORAGE_INDEX = "bx-payment-methods";

/*
  Questo store è un frammento del "shoppingCartStore"
*/
export const CartAtom = atomWithStorage<CartItems>(CART_STORAGE_INDEX, []);

export const PaymentMethodStore = atomWithStorage<PaymentMethods | undefined>(
  PAYMENT_METHOD_STORAGE_INDEX,
  undefined,
  undefined,
  { getOnInit: true }
);
