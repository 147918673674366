import { sendGTMEvent } from "../services/gtmEvents";

export const GTM = () => {
  const dataLayer = window.dataLayer;
  return { push: sendGTMEvent, dataLayer };
};

export const useGTM = () => {
  return GTM();
};
