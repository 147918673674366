import axios, { AxiosResponse } from "axios";
import { Account, AccountApi } from "bonusx-api-main-manager";
import useSWR from "swr";

import { defaultApiConfig } from "../common/env";
import { swrBaseConfig } from "../common/swrBaseConfig";
import { useAbility } from "./useAbilityRules";
import { useIsLogged } from "./useIsLogged";

const accountApi = new AccountApi(defaultApiConfig, undefined, axios);

export const useAccountSwrKey = "account-get-me";

export const useAccount = () => {
  const isLogged = useIsLogged();
  const abilityRules = useAbility();
  const abilited = abilityRules.can("readOne", "Account");
  const skip = !isLogged || !abilited;

  const fetcher = () => accountApi.getMe();
  const response = useSWR<AxiosResponse<Account>>(skip ? null : useAccountSwrKey, fetcher, swrBaseConfig);

  let accountData: Account | undefined = response.data?.data;

  return {
    accountData,
    revalidate: async () => {
      const data = await fetcher();
      return await response.mutate(data);
    },
    loading: response.isValidating,
    error: response.error,
    accountApi,
  };
};
